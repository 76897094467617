import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import FAQ from "../components/FAQ";
import Blogs from "../components/Blogs";
import ContactUs from "../components/ContactUs";
import HomeHeader from "../components/HomeHeader";
import HomePageImage from "../components/assets/HomePageImage";
import { Testimonials } from "../components/Testimonials";
import ContactPageImage from "../components/assets/ContactPageImage.svg"

function Home() {
    const FAQs = [
        {
            question: `What is the difference between growth and value stocks?`,
            answer: `Growth stocks are from companies expected to outpace the overall market growth, while value stocks are from companies the market currently undervalues but are likely to perform better in the future.`,
        },
        {
            question: `What is market capitalization?`,
            answer: `Market capitalization is the total market value of a company's shares. It's found by multiplying the current stock price by the total number of shares outstanding.`,
        },
        {
            question: ` What is the P/E ratio?`,
            answer: `The Price-to-Earnings (P/E) ratio compares a company's stock price to its earnings per share. It helps investors judge if a stock is priced fairly. To calculate it, divide the stock's current price by its earnings per share.`,
        },
        {
            question: `What is an IPO price band?`,
            answer: `The IPO Price Band is the price range set for a stock during its Initial Public Offering (IPO), with a lower and upper price limit. If this range changes, the offering period is extended, and the change is announced publicly.`
        },
        {
            question: `How can I apply for an IPO in India?`,
            answer: `To apply for an IPO in India, you can use online services of banks or brokers, or fill out a physical form from an intermediary. You'll need a Demat account and a bank account to apply.`
        }
    ];

    return (
        <div className="font-jakarta">
            <NavBar />
            <HomeHeader>
                <HomePageImage />
            </HomeHeader>
            <section className="bg-secondary">
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div className="flex flex-col items-center text-center">
                        <h2 className="text-3xl font-bold md:text-5xl">Our solution</h2>
                        <p className="mb-8 mt-4 max-w-xl text-base text-secondary-text md:mb-12 md:text-lg lg:mb-16"> An investment option for every goal</p>
                    </div>
                    <div className="grid gap-5 items-center sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
                        <a className="grid gap-4 rounded-md shadow-lg p-8 md:p-10 bg-tertiary" href="/solution1">
                            <i class="fa-solid fa-seedling text-4xl bg-white text-primary"></i>
                            <h1 className="text-xl font-bold">EMI Free Loan</h1>
                            <h3 className="text-sm">Get a secured Personal loan without EMI </h3>
                        </a >
                         <a className="grid gap-4 rounded-md shadow-lg p-8 md:p-10 bg-tertiary" href="/solution2">
                            <i class="fa-solid fa-money-bill-wave text-4xl bg-white text-primary"></i>
                            <h1 className="text-xl font-bold">Triple Play</h1>
                            <h3 className="text-sm">Multiply your 5 Lakh into thrice its value, a smart choice.</h3>
                        </a >
                        <a className="grid gap-4 rounded-md shadow-lg p-8 md:p-10 bg-tertiary" href="/solution3">
                            <i class="fa-solid fa-piggy-bank text-4xl bg-white text-primary"></i>
                            <h1 className="text-xl font-bold">Easy Earn</h1>
                            <h3 className="text-sm">Secure and easy 1 Lakh investment with a steady 3% gain</h3>
                        </a>
                             < a className="grid gap-4 rounded-md shadow-lg p-8 md:p-10 bg-tertiary" href="/solution4">
                            <i class="fa-solid fa-coins text-4xl bg-white text-primary"></i>
                            <h1 className="text-xl font-bold">Silver Gains</h1>
                            <h3 className="text-sm">Invest 25 Lakh for stable, silver-lined returns at 1.5% </h3>
                        </a >
                        <a className="grid gap-4 rounded-md shadow-lg p-8 md:p-10 bg-tertiary" href="/solution5">
                            <i class="fa fa-arrow-up text-4xl bg-white text-primary"></i>
                            <h1 className="text-xl font-bold">Zero To Peak</h1>
                            <h3 className="text-sm">Start with 1000, scale to financial peaks effortlessly. </h3>
                        </a>
                        <a className="grid gap-4 rounded-md shadow-lg p-8 md:p-10 bg-tertiary" href="/solution6">
                            <i class="fa-solid fa-rocket text-4xl bg-white text-primary"></i>
                            <h1 className="text-xl font-bold">Mega Multiplier</h1>
                            <h3 className="text-sm">32500 to 1040000 : experience exponential wealth growth</h3>
                        </a>
                    </div>
                </div>
            </section>
            {/* <section> // Not required now
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <h2 className="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16">We get results</h2>
                    <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
                        <div className="relative p-8 md:p-10">
                            <h3 className="mb-4 text-3xl font-bold md:text-5xl">1.5M</h3>
                            <p className="text-sm text-secondary-text">Lorem ipsum dolor sit amet, &nbsp;elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet luctus</p>
                            <div className="absolute right-0 top-[29%] hidden h-2/5 [border-right:1px_solid_rgb(230,_230,_230)] md:block"></div>
                        </div>
                        <div className="relative p-8 md:p-10">
                            <h3 className="mb-4 text-3xl font-bold md:text-5xl">41%</h3>
                            <p className="text-sm text-secondary-text">Lorem ipsum dolor sit amet, &nbsp;elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet luctus</p>
                            <div className="absolute right-0 top-[29%] hidden h-2/5 [border-right:1px_solid_rgb(230,_230,_230)] md:block"></div>
                        </div>
                        <div className="p-8 md:p-10">
                            <h3 className="mb-4 text-3xl font-bold md:text-5xl">3657+</h3>
                            <p className="text-sm text-secondary-text">Lorem ipsum dolor sit amet, &nbsp;elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet luctus</p>
                        </div>
                    </div>
                </div>
            </section> */}
            <section >
                <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div className="mx-auto max-w-3xl text-center">
                        <h2 className="mb-8 text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16">Recommendations</h2>
                    </div>
                    <div className="mb-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:mb-6 md:grid-cols-2 lg:mb-8">
                        <a className="grid grid-cols-1 gap-6 rounded-md shadow-lg shadow-grey-500/50 p-8 md:p-10" href="/recommendations">
                            <i class="fa fa-chart-line text-4xl bg-white text-primary"></i>
                            <h3 className="text-primary-text font-bold text-2xl -mb-4">Stocks</h3>
                            <p className="text-secondary-text">Discover Top Stocks: Expertly curated for optimal returns and growth potential.</p>
                        </a>
                        <a className="grid grid-cols-1 gap-6 rounded-md shadow-lg shadow-grey-500/50 p-8 md:p-10" href="/recommendations">
                            <i class="fa fa-certificate text-4xl bg-white text-primary"></i>
                            <h3 className="text-primary-text font-bold text-2xl -mb-4">IPOs</h3>
                            <p className="text-secondary-text">Explore Latest IPOs: Gain first access to promising, newly-listed companies.</p>
                        </a>
                    </div>
                </div>
            </section>
            <Blogs />
            <Testimonials />
            <section id="contactUs" className="bg-primary">
                <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div class="grid items-center gap-8 sm:gap-20 lg:grid-cols-2">
                        <div class="max-w-3xl text-tertiary">
                            <h2 class="mb-2 text-3xl font-bold md:text-5xl">Get In Touch</h2>
                            <p class="my-4 max-w-lg text-sm sm:text-base md:mb-6 lg:mb-8">Have questions or need guidance? Our team of experts is here to assist you on your investment journey.</p>
                            <img src={ContactPageImage} className="w-10/12 h-10/12 -mt-4" />
                        </div>
                        <ContactUs />    
                    </div>
                </div>
            </section>
            <section>
                <div className="justify-center mx-auto flex w-full max-w-7xl flex-col items-center px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div className="mx-auto flex max-w-[550px] flex-col items-center justify-center px-6 text-center lg:max-w-[800px] lg:px-10">
                        <p className="font-inter mb-2 text-center text-sm font-medium">FAQs</p>
                        <h1 className="mx-auto text-center font-bold text-black lg: text-3xl lg:text-4xl">Frequently Asked Questions</h1>
                    </div>
                    <FAQ FAQs={FAQs} />
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Home;
