import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ContactUs from "../components/ContactUs";

function Pricing() {
    const [showModel, setShowmodal] = React.useState(false)
    const [openCount, setOpenCount] = React.useState(0)
    return (
        <div className="font-jakarta">
            <NavBar />
            <section>
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24">
                    <div className="rounded-xl -mt-20 -mb-32 px-6 py-12 sm:px-12 sm:py-16 md:py-24">
                        <div className="mx-auto mb-4 flex max-w-3xl flex-col text-center md:mb-12 lg:mb-16">
                            <h2 className="text-3xl font-bold md:text-5xl">Simple &amp; Affordable Pricing</h2>
                        </div>
                        <div className="mx-auto grid h-auto w-full gap-4 rounded-md px-0 py-12 lg:grid-cols-2">
                            <div className="grid grid-cols-1 gap-4 rounded-md shadow-lg px-10 py-14 sm:grid-cols-2">
                                <div className="flex flex-row items-center gap-2">
                                    <i className="fa fa-check-circle text-xl bg-white text-primary"></i>
                                    <p className="text-[0.780rem] xs:text-base">One Credit, One Nifty Point</p>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <i className="fa fa-check-circle text-xl bg-white text-primary"></i>
                                    <p className="text-[0.780rem] xs:text-base">Complete Market Access</p>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <i className="fa fa-check-circle text-xl bg-white text-primary"></i>
                                    <p className="text-[0.780rem] xs:text-base">Customized Recommendations</p>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <i className="fa fa-check-circle text-xl bg-white text-primary"></i>
                                    <p className="text-[0.780rem] xs:text-base">Exclusive Research Reports</p>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <i className="fa fa-check-circle text-xl bg-white text-primary"></i>
                                    <p className="text-[0.780rem] xs:text-base">Priority Support</p>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <i className="fa fa-check-circle text-xl bg-white text-primary"></i>
                                    <p className="text-[0.780rem] xs:text-base">Flexible Usage</p>
                                </div>
                            </div>
                            <div className="flex flex-col rounded-md px-10 py-12 text-tertiary shadow-xl shadow-secondary bg-primary">
                                <div className="flex w-full flex-col items-center justify-between sm:flex-row sm:items-center">
                                    <div>
                                        <h2 className="text-3xl font-bold md:text-5xl">₹10/<span className="text-lg font-bold md:text-2xl">Advisory Point</span></h2>
                                        <p className="text-xs font-bold md:text-sm p-1">Minimum Purchase Value: ₹1000</p>
                                    </div>
                                    <span onClick={() => setShowmodal(true)} className="mt-4 rounded-md px-6 py-3 text-center font-semibold text-primary bg-tertiary shadow-lg md:mt-0">Contact Us</span>
                                </div>
                                <div className="mb-6 mt-6 border-[0.5px] border-neutral-300"></div>
                                <p className="max-[479px]:text-sm">Every Credit Unlocks a Door to Informed Investment Advice.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {
                showModel && <div class="h-screen"><div id="modal" class="flex items-center justify-center h-screen w-screen fixed top-0 bg-black bg-opacity-60 z-10">
                    <div class="bg-white max-w-xl w-full rounded-md">
                        <div class="p-3 flex items-end justify-end">
                            <span class="modal-close cursor-pointer text-3xl" onClick={() => setShowmodal(false)}>×</span>
                        </div>
                        <ContactUs />
                    </div>
                </div>
                </div>
            }
        </div>
    );
}

export default Pricing
