import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import HomeHeader from "../components/HomeHeader";
import Solution5PageImage from "../components/assets/Solution5PageImage";
import EasySteps from "../components/EasySteps";

function SolutionPage5() {
    return (
        <div className="font-jakarta">
            <NavBar />
            <HomeHeader title="Zero To Peak" description="Start with nothing, scale to financial peaks effortlessly.">
                <Solution5PageImage />
            </HomeHeader>
            <section>
            <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
            <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Zero To Peak</h2>
            <p class="text-base sm:text-lg text-primary-text">Are you looking for an investment opportunity but don't have the huge money to get started? We have just the solution for you our super plan!<br /><br />
 
 With our super plan, you can enter with monthly 1000 investment. If you invest 1000 per month for 20 years, you can get back up to 1515955 after 20 years.<br /><br />
  
   
 So why wait? Take advantage of our super plan today and start building your financial future. With our help, you'll be on your way to achieving your financial goals in no time!</p>

            </div>
            </section>
            <EasySteps />
            <Footer />
        </div>
    );
}
export default SolutionPage5;
