import React from "react";

function Solution6PageImage() {
    return (
        <div className="min-w-[50%]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 500">
                <g>
                    <path
                        fill="#f5f5f5"
                        d="M466.49 108.62A30.42 30.42 0 11432 83a30.38 30.38 0 0134.49 25.62z"
                    ></path>
                    <path
                        fill="#ebebeb"
                        d="M459.33 109.67a23.19 23.19 0 11-26.27-19.56 23.16 23.16 0 0126.27 19.56z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M459.22 109a14.16 14.16 0 01-1-2.67 24.32 24.32 0 00-3.67-6.65 22.9 22.9 0 00-8.71-6.86 22.26 22.26 0 00-6.3-1.81 22.08 22.08 0 00-3.47-.21 25.69 25.69 0 00-3.56.35A24.38 24.38 0 00429 92a23.42 23.42 0 00-3.19 1.38 22.73 22.73 0 00-2.81 1.79 20.74 20.74 0 00-2.45 2.09 23.2 23.2 0 00-3.68 4.68 23.7 23.7 0 00-2.13 4.76 24.34 24.34 0 00-1.16 7.5 13.86 13.86 0 010 2.85c-.11 0-.45-1-.67-2.82a21.49 21.49 0 01.64-7.89 23.06 23.06 0 015.86-10.23 23 23 0 012.59-2.3 23.59 23.59 0 013-2 24.62 24.62 0 013.43-1.52 26.87 26.87 0 013.73-1A25.3 25.3 0 01436 89a23.12 23.12 0 013.74.25 22.24 22.24 0 013.52.79 23.61 23.61 0 018.55 4.75 23.85 23.85 0 013.7 4.1 21.31 21.31 0 013.32 7.19 6.88 6.88 0 01.39 2.92z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M441 126.31l.64 4-3.56.57-.62-3.88c-3.56.35-7.12-.21-9.32-1.43l1.26-4.65a14.61 14.61 0 007.31 1.42l-.95-5.95c-4.11-.32-8.73-.94-9.55-6-.6-3.77 1.62-7.45 7.14-9l-.63-3.95 3.56-.57.62 3.88a16.28 16.28 0 017.58 1l-1.08 4.67a14.82 14.82 0 00-5.75-1l1 6c4.1.29 8.66.92 9.46 5.93.53 3.75-1.66 7.38-7.11 8.96zm-6.08-15.17l-.81-5.06c-1.82.73-2.4 1.89-2.21 3.06s1.34 1.75 2.99 2zm7.5 7.6c-.2-1.25-1.34-1.76-3-2l.79 4.92c1.73-.73 2.36-1.78 2.18-2.92z"
                    ></path>
                    <path
                        fill="#f5f5f5"
                        d="M413 54.9a23.15 23.15 0 11-16.91-28 23.12 23.12 0 0116.91 28z"
                    ></path>
                    <path
                        fill="#ebebeb"
                        d="M407.65 53.57a17.64 17.64 0 11-12.89-21.32 17.61 17.61 0 0112.89 21.32z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M407.78 53a10.91 10.91 0 01.06-2.17 18.58 18.58 0 00-.68-5.73 18.1 18.1 0 00-1.49-3.68 17.76 17.76 0 00-2.67-3.63 17.84 17.84 0 00-3.92-3.09 18.63 18.63 0 00-2.38-1.14 20.2 20.2 0 00-2.61-.78 21 21 0 00-2.7-.36 17.78 17.78 0 00-2.64.06 17 17 0 00-2.52.44 17.19 17.19 0 00-2.33.77 17.7 17.7 0 00-6.8 5 18.56 18.56 0 00-3 5 11 11 0 01-.84 2 5.16 5.16 0 01.34-2.18 16.31 16.31 0 012.72-5.37 17.57 17.57 0 017.07-5.53 16.12 16.12 0 012.48-.87 16.86 16.86 0 012.71-.51 18 18 0 012.85-.09 19.53 19.53 0 012.91.39 19.85 19.85 0 012.81.84 18 18 0 012.57 1.25 16.73 16.73 0 012.28 1.52 15.67 15.67 0 011.91 1.86 17.71 17.71 0 014.18 7.94 16.19 16.19 0 01.27 6 5 5 0 01-.58 2.06z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M389.94 60l-.7 3-2.67-.63.68-2.91a13 13 0 01-6.16-3.68l2.23-2.92a11.06 11.06 0 004.74 3.1l1-4.46c-2.8-1.41-5.88-3.17-5-7 .65-2.83 3.28-4.79 7.61-4.29l.69-3 2.67.62-.68 2.91a12.4 12.4 0 015.05 2.89l-2.1 3a11.22 11.22 0 00-3.77-2.34l-1.05 4.54c2.8 1.38 5.83 3.13 5 6.9-.64 2.83-3.23 4.76-7.54 4.27zm.06-12.41l.88-3.79c-1.49 0-2.23.63-2.43 1.51s.47 1.63 1.55 2.28zm3.1 7.51c.22-.94-.44-1.62-1.49-2.29l-.86 3.69c1.41.01 2.16-.5 2.36-1.4z"
                    ></path>
                    <path
                        fill="#f5f5f5"
                        d="M346.6 352.07a30.42 30.42 0 11-34.47-25.66 30.38 30.38 0 0134.47 25.66z"
                    ></path>
                    <path
                        fill="#ebebeb"
                        d="M339.44 353.11a23.18 23.18 0 11-26.27-19.56 23.15 23.15 0 0126.27 19.56z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M339.33 352.39a13.8 13.8 0 01-1-2.67 24.46 24.46 0 00-3.67-6.65 23.22 23.22 0 00-8.7-6.86 22.21 22.21 0 00-3-1.11 20.29 20.29 0 00-3.28-.7 22 22 0 00-3.46-.2 25.65 25.65 0 00-3.57.34 26.2 26.2 0 00-3.46.9 23.3 23.3 0 00-3.19 1.39 21.57 21.57 0 00-2.84 1.78 22.81 22.81 0 00-2.45 2.09 22.8 22.8 0 00-5.81 9.44 24.65 24.65 0 00-1.16 7.5 13.86 13.86 0 010 2.85 6.78 6.78 0 01-.67-2.81 21.47 21.47 0 01.64-7.89 23.16 23.16 0 015.86-10.24 22.16 22.16 0 012.6-2.29 22.46 22.46 0 013-2 23.5 23.5 0 013.43-1.52 24.33 24.33 0 013.73-1 26.67 26.67 0 013.85-.37 24.31 24.31 0 013.74.25 22.26 22.26 0 013.52.8 21.86 21.86 0 013.23 1.25 23.31 23.31 0 019 7.6 21.28 21.28 0 013.31 7.19 6.83 6.83 0 01.35 2.93z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M321.08 369.75l.64 4-3.56.57-.62-3.88a17.15 17.15 0 01-9.32-1.43l1.26-4.66a14.61 14.61 0 007.31 1.42l-1-5.94c-4.11-.33-8.73-.94-9.55-6-.6-3.78 1.62-7.46 7.15-9l-.64-4 3.56-.57.62 3.88a16.28 16.28 0 017.58 1l-1.08 4.66a14.8 14.8 0 00-5.74-1l1 6c4.09.3 8.65.92 9.45 5.94.62 3.8-1.58 7.44-7.06 9.01zM315 354.58l-.81-5.05c-1.82.73-2.4 1.88-2.21 3.05s1.38 1.76 3.02 2zm7.5 7.6c-.2-1.24-1.34-1.75-3-2l.79 4.91c1.76-.72 2.39-1.77 2.21-2.91z"
                    ></path>
                    <path
                        fill="#f5f5f5"
                        d="M29.6 310.61A32.85 32.85 0 1163 343.08a33 33 0 01-33.4-32.47zm48.56-.79a15.71 15.71 0 10-15.46 16 15.76 15.76 0 0015.46-16z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M29.6 310.61a33 33 0 0132.32-33.54l.28 17.23a15.78 15.78 0 00.5 31.56l.3 17.22a33 33 0 01-33.4-32.47z"
                    ></path>
                    <path
                        fill="#ebebeb"
                        d="M59 274.42a37.55 37.55 0 0138.44 37.4l-20.06.32c-.16-10.18-7.91-16.59-18-16.42z"
                    ></path>
                    <rect
                        width="36.27"
                        height="36.27"
                        x="311.05"
                        y="182.59"
                        fill="#e0e0e0"
                        rx="10.38"
                    ></rect>
                    <path
                        fill="#fff"
                        d="M330.76 200.54a4.47 4.47 0 002.65-4.12 4.18 4.18 0 10-8.35 0 4.45 4.45 0 002.66 4.16c-6.43 1-6.14 8-6.14 8l15.2.07s.35-7.15-6.02-8.11z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M281.34 241.55c0 .15-11.8.26-26.35.26s-26.35-.11-26.35-.26 11.79-.26 26.35-.26 26.35.12 26.35.26zM258.23 245.46c0 .14-6.62.26-14.79.26s-14.8-.12-14.8-.26 6.62-.26 14.8-.26 14.79.11 14.79.26z"
                    ></path>
                    <rect
                        width="18.73"
                        height="18.73"
                        x="200.25"
                        y="260.78"
                        fill="#f5f5f5"
                        rx="3.05"
                    ></rect>
                    <path
                        fill="#e0e0e0"
                        d="M281.34 269.24c0 .14-11.8.26-26.35.26s-26.35-.12-26.35-.26S240.43 269 255 269s26.34.1 26.34.24zM258.23 273.15c0 .14-6.62.26-14.79.26s-14.8-.12-14.8-.26 6.62-.26 14.8-.26 14.79.11 14.79.26zM210.66 277.25a2 2 0 01-1.32-.5l-5.83-5.1a2 2 0 012.64-3l4.28 3.75 8-9.59a2 2 0 013.08 2.55l-9.29 11.18a2 2 0 01-1.38.71z"
                    ></path>
                    <rect
                        width="18.73"
                        height="18.73"
                        x="200.25"
                        y="231.71"
                        fill="#f5f5f5"
                        rx="3.05"
                    ></rect>
                    <path
                        fill="#e0e0e0"
                        d="M210.66 248.18a2 2 0 01-1.32-.5l-5.83-5.1a2 2 0 012.64-3l4.28 3.75 8-9.6a2 2 0 013.08 2.56l-9.29 11.18a2 2 0 01-1.38.71z"
                    ></path>
                    <rect
                        width="18.73"
                        height="18.73"
                        x="200.25"
                        y="288.47"
                        fill="#f5f5f5"
                        rx="3.05"
                    ></rect>
                    <path
                        fill="#e0e0e0"
                        d="M281.34 296.93c0 .14-11.8.26-26.35.26s-26.35-.12-26.35-.26 11.79-.26 26.35-.26 26.35.11 26.35.26zM258.23 300.83c0 .15-6.62.26-14.79.26s-14.8-.11-14.8-.26 6.62-.26 14.8-.26 14.79.12 14.79.26zM210.66 304.94a2 2 0 01-1.32-.5l-5.83-5.1a2 2 0 012.64-3l4.28 3.75 8-9.6a2 2 0 013.08 2.56l-9.29 11.18a2 2 0 01-1.38.71z"
                    ></path>
                    <path fill="#f5f5f5" d="M188.53 359.54H215.35V469.35H188.53z"></path>
                    <path fill="#f5f5f5" d="M229.82 393.22H256.64V469.35H229.82z"></path>
                    <path
                        fill="#f5f5f5"
                        d="M271.12 427.4H297.94V469.34999999999997H271.12z"
                    ></path>
                </g>
                <path
                    fill="#263238"
                    d="M450.75 469.35c0 .14-89.44.26-199.74.26s-199.76-.12-199.76-.26 89.42-.26 199.76-.26 199.74.12 199.74.26z"
                ></path>
                <g>
                    <path
                        fill="#fafafa"
                        d="M85.69 122.46L83.47 115a25.85 25.85 0 10-6.19 6.89z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M85.69 122.46h-.52l-1.6-.08-6.31-.38a.11.11 0 01-.11-.11v-.07l.15.16a25.92 25.92 0 01-36.56-4.72 26 26 0 011.07-32.92 25.81 25.81 0 0110.31-7.19 26.48 26.48 0 0111.9-1.61 25.95 25.95 0 0119.5 39.52V115c.7 2.42 1.23 4.29 1.6 5.58.17.6.3 1.07.4 1.41a3.69 3.69 0 01.12.47l-.17-.5-.45-1.43c-.39-1.26-1-3.1-1.7-5.47a.1.1 0 010-.09A25.7 25.7 0 0064 76.07a26 26 0 00-11.67 1.59 25.32 25.32 0 00-10.1 7.06 25.58 25.58 0 0015.22 42.07 25.29 25.29 0 008.19 0 25.87 25.87 0 0011.56-5 .1.1 0 01.15 0 .11.11 0 010 .14l-.09-.18 6.23.46 1.62.13z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M54.2 101.5a2.63 2.63 0 11-2.62-2.62 2.62 2.62 0 012.62 2.62zM64.7 101.5a2.63 2.63 0 11-2.63-2.62 2.62 2.62 0 012.63 2.62zM75.16 100.19a2.62 2.62 0 11-3.58-1 2.61 2.61 0 013.58 1z"
                    ></path>
                </g>
                <g>
                    <path
                        fill="#0096FF"
                        d="M450.83 429.81a31.75 31.75 0 11-38.2-23.59 31.76 31.76 0 0138.2 23.59z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M412.63 406.22A31.74 31.74 0 00389 444.41s21.94-4.16 25.1-14.13-1.47-24.06-1.47-24.06z"
                        opacity="0.1"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M442.82 431.19a24.14 24.14 0 11-29-17.94 24.13 24.13 0 0129 17.94z"
                    ></path>
                    <path
                        d="M442.82 431.19a24.14 24.14 0 11-29-17.94 24.13 24.13 0 0129 17.94z"
                        opacity="0.2"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M443.71 432a23.81 23.81 0 11-28.65-17.7 23.81 23.81 0 0128.65 17.7z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M443.71 432a23.81 23.81 0 11-28.65-17.7 23.81 23.81 0 0128.65 17.7z"
                        opacity="0.2"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M421 435.26l-1.4-5.93a12.56 12.56 0 013.34.08 10.63 10.63 0 011.7.58v-.2l.41-2.35.1-.54-1.32-.86-.46.35a11.54 11.54 0 00-4.58-.23l-.77-3.26-1.21-.58.19.82-1.39.33.78 3.32c-4.48 1.52-6 4.65-5.32 7.65 1 4.08 4.71 4.31 8.15 4.33l1.39 5.88a11.56 11.56 0 01-6.4-.85l-.62 3.28a13.24 13.24 0 007.77.74l.58 2.46-1.14.29 1.33.51 2.41-.57-.78-3.29c4.5-1.56 6.1-4.66 5.39-7.66-.92-4.09-4.71-4.28-8.15-4.3zm-2.56-.05c-1.82-.1-3.23-.44-3.57-1.85s.33-2.56 2.34-3.38zm1.16-5.89zm3.46 14.68l-1.23-5.2c1.88.08 3.33.45 3.67 1.89.3 1.31-.38 2.49-2.44 3.31z"
                    ></path>
                    <path
                        d="M421 435.26l-1.4-5.93a12.56 12.56 0 013.34.08 10.63 10.63 0 011.7.58v-.2l.41-2.35.1-.54-1.32-.86-.46.35a11.54 11.54 0 00-4.58-.23l-.77-3.26-1.21-.58.19.82-1.39.33.78 3.32c-4.48 1.52-6 4.65-5.32 7.65 1 4.08 4.71 4.31 8.15 4.33l1.39 5.88a11.56 11.56 0 01-6.4-.85l-.62 3.28a13.24 13.24 0 007.77.74l.58 2.46-1.14.29 1.33.51 2.41-.57-.78-3.29c4.5-1.56 6.1-4.66 5.39-7.66-.92-4.09-4.71-4.28-8.15-4.3zm-2.56-.05c-1.82-.1-3.23-.44-3.57-1.85s.33-2.56 2.34-3.38zm1.16-5.89zm3.46 14.68l-1.23-5.2c1.88.08 3.33.45 3.67 1.89.3 1.31-.38 2.49-2.44 3.31z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M422.63 446.64l.77 3.29-2.4.57-.77-3.26a13.11 13.11 0 01-7.78-.74l.62-3.28a11.58 11.58 0 006.41.85l-1.39-5.88c-3.45 0-7.2-.25-8.16-4.33-.71-3 .85-6.13 5.32-7.65l-.78-3.32 2.41-.57.77 3.26a13 13 0 016.35.42l-.51 3.25a12.54 12.54 0 00-5.13-.46l1.4 5.94c3.45 0 7.25.21 8.21 4.29.75 2.98-.84 6.06-5.34 7.62zm-5.38-12l-1.25-5.23c-2 .82-2.64 2.08-2.33 3.37s1.76 1.76 3.58 1.86zm7.06 5.49c-.34-1.44-1.79-1.81-3.67-1.89l1.23 5.2c2.07-.83 2.74-2.04 2.44-3.31z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M425.64 461l.19-.07.55-.17.89-.27a9.28 9.28 0 001.21-.42l1.48-.6a6.85 6.85 0 00.82-.4l.87-.46c.29-.17.62-.31.92-.52l.94-.62c.31-.22.66-.42 1-.67l1-.79a26.19 26.19 0 003.88-4l.86-1.27c.15-.22.3-.43.44-.65l.39-.7c.25-.47.54-.94.78-1.43l.67-1.54.17-.39c.05-.13.09-.27.13-.4l.27-.83.27-.83a8.11 8.11 0 00.21-.85c.12-.58.27-1.16.37-1.76s.15-1.2.22-1.81a23.65 23.65 0 00-3.45-14.58c-.33-.52-.67-1-1-1.53s-.75-.94-1.12-1.4c-.2-.23-.36-.47-.57-.67l-.62-.63-.6-.61c-.11-.1-.2-.21-.31-.3l-.32-.28-1.29-1.07c-.43-.34-.9-.63-1.34-.94l-.65-.45-.69-.38-1.34-.74a25.36 25.36 0 00-5.27-1.87l-1.22-.27c-.4-.08-.8-.11-1.18-.16l-1.2-.17c-.36-.05-.71 0-1 0h-1a6.84 6.84 0 00-.91 0l-1.59.11a9.64 9.64 0 00-1.26.17l-.92.16-.57.1h-.2l.19-.05.57-.11.92-.18a9.27 9.27 0 011.26-.2l1.6-.14a6.8 6.8 0 01.91 0h2.06l1.13.13c.38.05.78.07 1.18.15l1.24.27a25 25 0 015.31 1.85l1.36.74c.23.13.47.25.69.39l.67.45c.44.31.91.6 1.35.94l1.3 1.08.33.27.31.31.61.62.62.62c.22.21.39.46.58.68.37.47.77.92 1.13 1.42s.68 1 1 1.54a23.78 23.78 0 013.48 14.72c-.07.61-.15 1.22-.22 1.83s-.26 1.18-.38 1.77a7.71 7.71 0 01-.21.86c-.1.28-.19.56-.28.84l-.27.83c-.05.14-.09.28-.14.41l-.17.39L442 448c-.24.5-.53 1-.79 1.44l-.39.71c-.14.22-.3.43-.45.65l-.88 1.27a25.2 25.2 0 01-3.92 4l-1 .79c-.32.25-.67.45-1 .67l-.95.61a8.56 8.56 0 01-.93.51l-.88.46a7.75 7.75 0 01-.83.39l-1.49.59a8.89 8.89 0 01-1.22.39l-.9.25-.55.15z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M397.05 457.82s-.08-.06-.2-.2l-.54-.62c-.24-.27-.52-.6-.82-1s-.66-.86-1-1.4l-.55-.84c-.19-.29-.36-.61-.55-.93-.4-.64-.74-1.37-1.12-2.12a31.55 31.55 0 01-2.94-10.54c-.06-.84-.14-1.64-.14-2.4v-2.08c0-.64.09-1.22.14-1.72s.11-.94.17-1.29l.15-.81a.87.87 0 01.06-.27 1.18 1.18 0 010 .28c0 .21-.06.48-.1.82s-.1.78-.13 1.28-.1 1.08-.1 1.71v2.08c0 .75.09 1.54.16 2.38a35.87 35.87 0 001 5.35 35.22 35.22 0 001.9 5.11c.38.76.71 1.48 1.1 2.12.18.32.35.64.53.94l.54.84c.33.54.68 1 1 1.41s.56.76.78 1l.51.65a1.5 1.5 0 01.15.25zM412.52 435.84a2.09 2.09 0 01-.85-.52 3.75 3.75 0 01-.88-4.43 2.35 2.35 0 01.58-.8s-.2.32-.42.87a4 4 0 00-.23 2.25 4 4 0 001.07 2c.41.41.75.59.73.63zM415.64 425.6c-.07 0-.22-.4-.35-.93a2.35 2.35 0 01-.11-1 4.42 4.42 0 01.46 1.93zM415.76 412.14a4.3 4.3 0 01-.76.15c-.25 0-.55.09-.9.17s-.74.14-1.17.26-.9.23-1.4.39-1 .35-1.57.54a28.06 28.06 0 00-3.45 1.64 26 26 0 00-3.16 2.15c-.45.38-.89.72-1.27 1.09s-.73.69-1 1-.58.61-.82.87-.44.5-.59.69a4.26 4.26 0 01-.52.58 7.49 7.49 0 01.44-.64c.15-.2.34-.44.57-.71s.48-.59.8-.91.64-.68 1-1 .81-.73 1.26-1.11a26 26 0 013.18-2.18 24.63 24.63 0 013.49-1.64c.56-.19 1.09-.39 1.6-.53s1-.27 1.41-.36.84-.17 1.19-.23a7.78 7.78 0 01.9-.13 7.3 7.3 0 01.77-.09zM430.62 465.52a3 3 0 01-.51.28c-.34.17-.84.38-1.47.63a23.37 23.37 0 01-5.12 1.27 22.94 22.94 0 01-5.26.08c-.68-.07-1.22-.16-1.59-.23a4.36 4.36 0 01-.57-.14 2.36 2.36 0 01.59 0c.37.05.91.1 1.58.15a25.57 25.57 0 0010.31-1.34c.63-.22 1.14-.41 1.49-.56a2.17 2.17 0 01.55-.14z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M450.83 429.81l-.14-.5c-.1-.32-.21-.82-.42-1.45a31.74 31.74 0 00-4.38-8.94 31.27 31.27 0 00-3.18-3.83 32.13 32.13 0 00-9.91-6.91 31.68 31.68 0 00-14.33-2.68 31.53 31.53 0 00-8.05 1.44 32.53 32.53 0 00-4 1.55 36.37 36.37 0 00-3.84 2.15 31.75 31.75 0 00-11.77 14.12 30.47 30.47 0 00-1.56 4.64 29.56 29.56 0 00-.83 4.89 31.63 31.63 0 004.58 19.44c.43.72.92 1.39 1.39 2.07s1 1.32 1.55 1.94a31.3 31.3 0 003.46 3.45 31.79 31.79 0 0016.86 7.35 32.42 32.42 0 004.39.21 33.21 33.21 0 004.27-.4 31.68 31.68 0 007.84-2.32 31.81 31.81 0 0011.61-8.81 32.15 32.15 0 005.77-10.61 32.7 32.7 0 001.48-9 32.13 32.13 0 00-.44-5.78c-.09-.66-.22-1.15-.28-1.48l-.09-.51s.18.67.42 2a30.63 30.63 0 01.14 10 30.1 30.1 0 01-1.11 4.88 32 32 0 01-5.76 10.67 31.37 31.37 0 01-5.07 4.95 30.92 30.92 0 01-6.59 3.94 32.54 32.54 0 01-12.16 2.72 34.82 34.82 0 01-4.43-.2 31.92 31.92 0 01-17-7.4 30.9 30.9 0 01-3.49-3.47c-.55-.62-1-1.29-1.56-1.95s-1-1.36-1.4-2.09a31.76 31.76 0 01-4.62-19.58 29 29 0 01.84-4.93 32.39 32.39 0 011.56-4.67 32 32 0 0111.87-14.21 35.16 35.16 0 013.87-2.16 33 33 0 014-1.56 31.83 31.83 0 018.1-1.44 31.79 31.79 0 0114.45 2.66 32.05 32.05 0 019.93 7 29.72 29.72 0 013.18 3.86 31 31 0 012.17 3.6 30.48 30.48 0 012.17 5.39c.37 1.28.51 1.96.51 1.96z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M427 234.44a27.66 27.66 0 11-23.47-31.31A27.66 27.66 0 01427 234.44z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M403.51 203.13a27.67 27.67 0 00-31.3 23.47s19.12 3.62 24.86-3.47 6.44-20 6.44-20z"
                        opacity="0.1"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M420 233a21 21 0 11-17.84-23.8A21 21 0 01420 233z"
                    ></path>
                    <path
                        d="M420 233a21 21 0 11-17.84-23.8A21 21 0 01420 233z"
                        opacity="0.2"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M420.51 234a20.75 20.75 0 11-17.61-23.48A20.76 20.76 0 01420.51 234z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M420.51 234a20.75 20.75 0 11-17.61-23.48A20.76 20.76 0 01420.51 234z"
                        opacity="0.2"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M401 229.35l.75-5.25a11.2 11.2 0 012.68 1.13 9.3 9.3 0 011.2 1l.09-.15 1.08-1.78.25-.4-.8-1.12-.48.14a9.94 9.94 0 00-3.65-1.65l.42-2.89-.8-.85-.1.72-1.24-.17-.42 2.94c-4.12-.19-6.38 1.85-6.76 4.51-.51 3.62 2.45 5 5.24 6.11l-.74 5.21a10.17 10.17 0 01-4.93-2.72l-1.55 2.46a11.55 11.55 0 006.07 3.07l-.31 2.18-1-.12.92.83 2.14.31.41-2.92c4.15.17 6.43-1.84 6.81-4.5.57-3.61-2.45-4.97-5.28-6.09zm-2-.85c-1.45-.66-2.48-1.39-2.3-2.64s1.08-2 3-2zm2.81-4.4zm-1.86 13l.66-4.6c1.5.66 2.56 1.42 2.37 2.7-.18 1.13-1.11 1.9-3.05 1.91z"
                    ></path>
                    <path
                        d="M401 229.35l.75-5.25a11.2 11.2 0 012.68 1.13 9.3 9.3 0 011.2 1l.09-.15 1.08-1.78.25-.4-.8-1.12-.48.14a9.94 9.94 0 00-3.65-1.65l.42-2.89-.8-.85-.1.72-1.24-.17-.42 2.94c-4.12-.19-6.38 1.85-6.76 4.51-.51 3.62 2.45 5 5.24 6.11l-.74 5.21a10.17 10.17 0 01-4.93-2.72l-1.55 2.46a11.55 11.55 0 006.07 3.07l-.31 2.18-1-.12.92.83 2.14.31.41-2.92c4.15.17 6.43-1.84 6.81-4.5.57-3.61-2.45-4.97-5.28-6.09zm-2-.85c-1.45-.66-2.48-1.39-2.3-2.64s1.08-2 3-2zm2.81-4.4zm-1.86 13l.66-4.6c1.5.66 2.56 1.42 2.37 2.7-.18 1.13-1.11 1.9-3.05 1.91z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M398.74 239.11l-.42 2.92-2.13-.31.41-2.89a11.55 11.55 0 01-6.07-3.07l1.55-2.46A10.18 10.18 0 00397 236l.75-5.21c-2.79-1.11-5.75-2.5-5.24-6.12.38-2.65 2.64-4.69 6.76-4.5l.42-2.95 2.14.31-.42 2.89a11.14 11.14 0 015.06 2.35l-1.47 2.49a11.09 11.09 0 00-4-2l-.75 5.26c2.79 1.12 5.81 2.48 5.29 6.1-.37 2.65-2.65 4.66-6.8 4.49zm-.54-11.45l.66-4.63c-1.89 0-2.8.84-3 2s.89 1.97 2.34 2.63zm4 6.71c.19-1.28-.87-2-2.37-2.71l-.66 4.61c1.92-.01 2.83-.78 3.01-1.9z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M396.62 251.71h.67l.81.06a7.6 7.6 0 001.11.05h1.39a5.59 5.59 0 00.8-.06l.85-.1c.29 0 .6-.05.91-.12l1-.21a9.73 9.73 0 001-.23l1-.33a22.43 22.43 0 004.43-2l1.1-.75.57-.39.54-.44c.35-.3.73-.59 1.08-.92l1-1 .27-.26c.08-.09.15-.19.23-.29l.48-.58.49-.59a7.07 7.07 0 00.44-.63c.28-.43.59-.85.86-1.3l.76-1.4a20.9 20.9 0 001.85-12.93c-.12-.52-.23-1-.34-1.56s-.31-1-.46-1.49a6.28 6.28 0 00-.25-.73l-.3-.7c-.09-.23-.19-.46-.29-.69l-.15-.34-.18-.33-.7-1.28c-.25-.41-.53-.79-.79-1.18l-.39-.58-.43-.53-.85-1a22.48 22.48 0 00-3.68-3.19l-.91-.61c-.3-.19-.61-.34-.9-.5l-.87-.47a7.68 7.68 0 00-.84-.37l-.79-.34a7.44 7.44 0 00-.74-.28l-1.33-.41a8.31 8.31 0 00-1.08-.26l-.8-.17-.49-.1-.16-.05h.17l.49.08.8.15a6.92 6.92 0 011.09.25l1.34.39a6.19 6.19 0 01.75.27l.8.33a8.77 8.77 0 01.85.37l.87.46c.29.17.61.31.91.5l.92.61a22.28 22.28 0 013.72 3.2l.87 1c.14.18.3.35.44.54l.39.58c.26.39.55.77.8 1.19l.71 1.29.18.32c.06.12.1.23.15.35l.3.7.31.7c.11.24.16.49.25.74.15.49.33 1 .47 1.5l.33 1.58a22.17 22.17 0 01.13 6.67 23 23 0 01-2 6.38l-.77 1.41c-.27.46-.58.88-.87 1.32a7.17 7.17 0 01-.45.63c-.16.2-.32.4-.49.59l-.48.59-.24.29-.27.26-1 1c-.36.33-.74.62-1.1.92l-.54.44c-.19.14-.39.26-.57.39l-1.12.76a22.32 22.32 0 01-4.47 2l-1.05.33c-.35.1-.69.15-1 .22l-1 .2c-.31.07-.62.08-.92.12l-.85.09a7.47 7.47 0 01-.8.05h-1.4a6.22 6.22 0 01-1.11-.07l-.81-.08-.5-.06z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M374.43 240a1.17 1.17 0 01-.1-.23l-.24-.67c-.1-.29-.22-.65-.33-1.08s-.27-.91-.37-1.45c-.06-.28-.12-.56-.18-.86s-.1-.61-.15-.93c-.12-.65-.17-1.35-.24-2.08a27.12 27.12 0 011-9.48c.21-.71.41-1.38.65-2 .12-.3.22-.6.34-.88l.35-.8c.21-.52.46-1 .66-1.35a8.41 8.41 0 01.55-1l.37-.61.14-.21s0 .08-.1.23l-.34.63a10 10 0 00-.52 1c-.19.4-.43.84-.63 1.36l-.34.8c-.11.27-.21.57-.32.88-.23.61-.42 1.28-.63 2a30.54 30.54 0 00-.88 4.67 31.91 31.91 0 00-.09 4.75c.06.73.11 1.42.22 2.06 0 .32.08.64.13.93s.12.58.17.85c.09.55.23 1 .34 1.46s.21.79.3 1.09l.2.68a.78.78 0 01.04.24zM394 227.13a1.92 1.92 0 01-.52-.69 3.33 3.33 0 01-.3-2.05 3.24 3.24 0 011-1.82 1.79 1.79 0 01.72-.47s-.26.19-.62.57a3.38 3.38 0 00-.67 3.71c.19.47.39.73.39.75zM399.77 219.82a2 2 0 010-.87c.07-.47.17-.85.23-.84a2.14 2.14 0 010 .87c-.07.47-.17.85-.23.84zM404.16 208.94a5.22 5.22 0 01-.67-.12 7.54 7.54 0 00-.77-.15c-.31 0-.65-.12-1-.16s-.8-.11-1.26-.13-.94 0-1.45-.06a21.83 21.83 0 00-6.56 1c-.49.16-1 .31-1.38.48s-.81.33-1.17.49-.66.31-.94.45-.51.26-.7.37a4.59 4.59 0 01-.6.31 3 3 0 01.56-.38 6.41 6.41 0 01.69-.4c.27-.15.58-.33.93-.49s.74-.35 1.17-.51.89-.33 1.38-.5a23.5 23.5 0 013.27-.76 22.06 22.06 0 013.35-.21c.52 0 1 0 1.47.08s.88.09 1.26.15.74.13 1 .19.56.13.77.19zM399.21 257a2.33 2.33 0 01-.5.06 13.54 13.54 0 01-1.4 0 19.89 19.89 0 01-8.85-2.21 15.6 15.6 0 01-1.21-.69 2.8 2.8 0 01-.41-.29 3.1 3.1 0 01.45.22c.29.16.71.38 1.24.63a22.35 22.35 0 004.28 1.55 23.19 23.19 0 004.51.65h1.38a2.91 2.91 0 01.51.08z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M427 234.44v-.45c0-.3.1-.73.13-1.31a28.33 28.33 0 00-.08-5.05 27.23 27.23 0 00-.63-3.59 26.14 26.14 0 00-1.36-4.13 27.89 27.89 0 00-5.83-8.75 27.54 27.54 0 00-10.78-6.75 27.71 27.71 0 00-7-1.39 27.3 27.3 0 00-3.73 0 27.5 27.5 0 00-17.85 8.22 26.86 26.86 0 00-2.74 3.26 25.69 25.69 0 00-2.23 3.71 27.56 27.56 0 00-2.47 17.23c.12.72.3 1.42.46 2.13s.4 1.38.64 2.06a26.68 26.68 0 001.72 3.9 27.63 27.63 0 0011.32 11.33 28.76 28.76 0 003.51 1.57 26.35 26.35 0 003.58 1 27 27 0 007.1.62 26.7 26.7 0 006.56-1.08 27 27 0 005.67-2.37 27.94 27.94 0 008.06-6.77 26 26 0 002.45-3.58 27.39 27.39 0 001.62-3.27 27.92 27.92 0 001.49-4.83c.13-.56.19-1 .25-1.29s.08-.44.08-.44-.07.61-.29 1.74a26.8 26.8 0 01-1.46 4.85 25.19 25.19 0 01-1.6 3.29 26.74 26.74 0 01-2.45 3.6 28 28 0 01-8.08 6.83 27.89 27.89 0 01-12.3 3.5 27.81 27.81 0 01-7.14-.62 26.8 26.8 0 01-3.61-1 28.9 28.9 0 01-3.52-1.57 27.85 27.85 0 01-13.15-15.34c-.25-.67-.44-1.37-.64-2.07s-.35-1.42-.47-2.14a27.81 27.81 0 01-.17-8.88 28.23 28.23 0 012.65-8.48 25 25 0 012.26-3.73 26.48 26.48 0 012.75-3.29 27.91 27.91 0 0114.16-7.75 29.92 29.92 0 013.82-.52 28.43 28.43 0 0110.79 1.43 26.93 26.93 0 016 2.91 27.36 27.36 0 014.79 3.89 28 28 0 015.84 8.82 28.06 28.06 0 012 7.75 26.89 26.89 0 010 5.07c-.04 1.13-.12 1.73-.12 1.73z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M331.33 379.18H401.61V391.94H331.33z"
                        transform="rotate(180 366.475 385.555)"
                    ></path>
                    <path
                        fill="#263238"
                        d="M394.73 392a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM398.57 392a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM386.28 392a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM390.13 392a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.17 6.33.3 6.33zM377.84 392a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM381.68 392a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM369.39 392a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM373.24 392a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM361 392a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM364.79 392a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM352.5 392c.17 0 .3-2.84.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM356.35 392a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM344.06 392a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM347.9 392c.17 0 .3-2.84.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM335.62 392a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM339.46 392a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M331.33 379.18c0 .16 15.81.29 35.3.29s35.31-.13 35.31-.29-15.8-.3-35.31-.3-35.3.12-35.3.3z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M335.93 392.14H406.21000000000004V404.9H335.93z"
                        transform="rotate(180 371.075 398.52)"
                    ></path>
                    <path
                        fill="#263238"
                        d="M399.33 405a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM403.17 405a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM390.88 405a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM394.73 405a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM382.44 405a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM386.28 405a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM374 405a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM377.84 405a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM365.55 405a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM369.39 405a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM357.1 405c.17 0 .3-2.84.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM361 405a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM348.66 405a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM352.5 405c.17 0 .3-2.84.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM340.22 405a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM344.06 405a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M335.93 392.14c0 .16 15.81.29 35.3.29s35.31-.13 35.31-.29-15.8-.29-35.31-.29-35.3.15-35.3.29z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M330.19 404.99H400.47V417.75H330.19z"
                        transform="rotate(180 365.33 411.37)"
                    ></path>
                    <path
                        fill="#263238"
                        d="M393.58 417.84a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM397.43 417.84a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM385.14 417.84a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM389 417.84a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM376.69 417.84c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM380.54 417.84a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM368.25 417.84a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM372.09 417.84c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM359.81 417.84a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM363.65 417.84a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM351.36 417.84a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM355.21 417.84a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.09 6.33.3 6.33zM342.92 417.84a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM346.76 417.84a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM334.47 417.84a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM338.32 417.84a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M330.19 405c0 .16 15.85.29 35.39.29S401 405.15 401 405s-15.84-.29-35.38-.29-35.43.12-35.43.29z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M335.7 417.65H405.98V430.40999999999997H335.7z"
                        transform="rotate(180 370.84 424.03)"
                    ></path>
                    <path
                        fill="#263238"
                        d="M399.09 430.5a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM402.94 430.5a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM390.65 430.5a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM394.49 430.5a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM382.2 430.5a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM386.05 430.5a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM373.76 430.5a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM377.6 430.5a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM365.31 430.5c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM369.16 430.5a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM356.87 430.5a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM360.71 430.5c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM348.43 430.5a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33zM352.27 430.5a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM340 430.5a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM343.83 430.5a69.23 69.23 0 000-12.66c-.17 0-.3 2.83-.3 6.33s.13 6.33.3 6.33z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M335.7 417.65c0 .16 15.81.29 35.3.29s35.3-.13 35.3-.29-15.8-.29-35.3-.29-35.3.13-35.3.29z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M328.66 430.6H398.94000000000005V443.36H328.66z"
                        transform="rotate(180 363.795 436.975)"
                    ></path>
                    <path
                        fill="#263238"
                        d="M392.05 443.45a69.34 69.34 0 000-12.67 69.34 69.34 0 000 12.67zM395.89 443.45a67 67 0 000-12.67 69.34 69.34 0 000 12.67zM383.6 443.45c.17 0 .3-2.84.3-6.33s-.13-6.34-.3-6.34a69.34 69.34 0 000 12.67zM387.45 443.45a69.34 69.34 0 000-12.67 69.34 69.34 0 000 12.67zM375.16 443.45a69.34 69.34 0 000-12.67 69.34 69.34 0 000 12.67zM379 443.45c.17 0 .3-2.84.3-6.33s-.13-6.34-.3-6.34a69.34 69.34 0 000 12.67zM366.72 443.45a69.34 69.34 0 000-12.67c-.17 0-.3 2.84-.3 6.34s.13 6.33.3 6.33zM370.56 443.45a69.34 69.34 0 000-12.67 69.34 69.34 0 000 12.67zM358.27 443.45a69.34 69.34 0 000-12.67 69.34 69.34 0 000 12.67zM362.12 443.45a69.34 69.34 0 000-12.67c-.17 0-.3 2.84-.3 6.34s.18 6.33.3 6.33zM349.83 443.45a69.34 69.34 0 000-12.67 67 67 0 000 12.67zM353.67 443.45a69.34 69.34 0 000-12.67 69.34 69.34 0 000 12.67zM341.38 443.45a67 67 0 000-12.67 69.34 69.34 0 000 12.67zM345.23 443.45a69.34 69.34 0 000-12.67 67 67 0 000 12.67zM332.94 443.45a69.34 69.34 0 000-12.67 69.34 69.34 0 000 12.67zM336.78 443.45a67 67 0 000-12.67 69.34 69.34 0 000 12.67z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M328.66 430.59c0 .17 15.8.3 35.3.3s35.3-.13 35.3-.3-15.8-.29-35.3-.29-35.3.13-35.3.29z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M333.06 443.5H403.34000000000003V456.26H333.06z"
                        transform="rotate(180 368.205 449.885)"
                    ></path>
                    <path
                        fill="#263238"
                        d="M396.46 456.35a69.23 69.23 0 000-12.66c-.17 0-.3 2.84-.3 6.33s.13 6.33.3 6.33zM400.3 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM388 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM391.86 456.35a69.23 69.23 0 000-12.66c-.17 0-.3 2.84-.3 6.33s.13 6.33.3 6.33zM379.57 456.35a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM383.41 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM371.12 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM375 456.35a69.23 69.23 0 000-12.66c-.17 0-.3 2.84-.3 6.33s.1 6.33.3 6.33zM362.68 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM366.52 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM354.23 456.35c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM358.08 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM345.79 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM349.63 456.35a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM337.35 456.35a69.23 69.23 0 000-12.66c-.17 0-.3 2.84-.3 6.33s.13 6.33.3 6.33zM341.19 456.35a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M333.06 443.5c0 .17 15.85.3 35.39.3s35.39-.13 35.39-.3-15.84-.29-35.39-.29-35.39.13-35.39.29z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M338.57 456.16H408.85V468.92H338.57z"
                        transform="rotate(180 373.71 462.545)"
                    ></path>
                    <path
                        fill="#263238"
                        d="M402 469c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM405.81 469a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM393.52 469a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM397.36 469c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM385.08 469a69.23 69.23 0 000-12.66c-.17 0-.3 2.84-.3 6.33s.13 6.33.3 6.33zM388.92 469a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM376.63 469a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM380.48 469a69.23 69.23 0 000-12.66c-.17 0-.3 2.84-.3 6.33s.13 6.33.3 6.33zM368.19 469a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM372 469a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM359.74 469a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM363.59 469a69.23 69.23 0 000-12.66 66.93 66.93 0 000 12.66zM351.3 469a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66zM355.14 469a66.93 66.93 0 000-12.66 69.23 69.23 0 000 12.66zM342.85 469c.17 0 .3-2.83.3-6.33s-.13-6.33-.3-6.33a69.23 69.23 0 000 12.66zM346.7 469a69.23 69.23 0 000-12.66 69.23 69.23 0 000 12.66z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M338.57 456.16c0 .17 15.81.3 35.3.3s35.31-.13 35.31-.3-15.81-.29-35.31-.29-35.3.13-35.3.29z"
                    ></path>
                    <path
                        d="M400.47 405a107.28 107.28 0 01-32.47 2.35c-10.78-.72-21.67-2.3-32.48-2.17zM399.84 392.29a107.6 107.6 0 01-32.47 2.36c-10.78-.72-21.67-2.31-32.48-2.17zM396.43 417.78a107.21 107.21 0 01-32.43 2.36c-10.79-.72-21.68-2.3-32.48-2.17zM399.19 430.52a107.28 107.28 0 01-32.47 2.36c-10.78-.72-21.67-2.3-32.48-2.17zM398.35 443.21a107.21 107.21 0 01-32.46 2.36c-10.79-.72-21.68-2.3-32.48-2.17zM401.79 456.14a107.28 107.28 0 01-32.47 2.36c-10.78-.71-21.67-2.3-32.48-2.17z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#fff"
                        d="M400.32 387.9a20.45 20.45 0 000-7 21.15 21.15 0 000 7zM404.76 401.54a24.39 24.39 0 000-7.5c-.17 0-.3 1.68-.3 3.75s.13 3.75.3 3.75zM398.45 414.67a19.21 19.21 0 000-6.65 19.21 19.21 0 000 6.65zM404.93 426.77a15.51 15.51 0 000-6 15 15 0 000 6zM379.19 393.19c0 .16 5.19.29 11.59.29s11.59-.13 11.59-.29-5.19-.29-11.59-.29-11.59.1-11.59.29zM371.69 380.92c0 .16 5.23.29 11.67.29s11.68-.13 11.68-.29-5.23-.3-11.68-.3-11.67.13-11.67.3zM360.44 381.09a25.5 25.5 0 007.67 0 24.66 24.66 0 00-7.67 0zM397.94 442.9a56.81 56.81 0 000-9.76 54.23 54.23 0 000 9.76zM344.53 429.09c0 .12 12.78.21 28.54.21s28.55-.09 28.55-.21-12.78-.22-28.55-.22-28.54.13-28.54.22zM356 454.87c0 .12 10.1.21 22.56.21s22.56-.09 22.56-.21-10.1-.22-22.56-.22-22.56.1-22.56.22zM407.88 466.47a18.31 18.31 0 000-5.53 17.49 17.49 0 000 5.53zM358.16 467.57c0 .12 10.47.22 23.39.22s23.38-.1 23.38-.22-10.47-.21-23.38-.21-23.39.09-23.39.21z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M408.85 468.93a.72.72 0 000-.23v-.68c0-.63 0-1.49.05-2.58 0-2.28 0-5.48.07-9.39v-.17h-5.17l.19.19v-12.96h-4.86l.21.22v-12.95l-.19.2h6.96v-.17c0-3.88-.06-7.06-.08-9.31v-3.22a.6.6 0 000-.23.72.72 0 000 .23v.66c0 .63 0 1.48-.05 2.56 0 2.25 0 5.43-.07 9.31l.17-.17H399v13.35h4.87l-.22-.21v12.93h.19l5-.07-.17-.17c0 3.91.06 7.11.08 9.39V468.72a.6.6 0 00.1.21z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M327.08 284.62c6.83 8 17.76 9.81 24.4 4.12S358 272 351.14 264s-17.76-9.82-24.41-4.12-6.49 16.76.35 24.74z"
                    ></path>
                    <path
                        d="M327.08 284.62c6.83 8 17.76 9.81 24.4 4.12S358 272 351.14 264s-17.76-9.82-24.41-4.12-6.49 16.76.35 24.74z"
                        opacity="0.2"
                    ></path>
                    <ellipse
                        cx="341.44"
                        cy="272.3"
                        fill="#0096FF"
                        rx="15.85"
                        ry="19.01"
                        transform="rotate(-40.61 341.39 272.283)"
                    ></ellipse>
                    <path
                        fill="#fff"
                        d="M325.11 263.64a13.68 13.68 0 013.95-5.77c6.65-5.7 17.57-3.85 24.41 4.12a21.53 21.53 0 015 10.75 32.84 32.84 0 01-13.5 2.95 18.39 18.39 0 01-12.48-4.3c-2.41-2.12-5.71-4.66-7.38-7.75z"
                        opacity="0.1"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M332.34 279.64c5.25 6.13 13.57 7.61 18.59 3.32s4.82-12.76-.43-18.88-13.58-7.61-18.6-3.31-4.82 12.75.44 18.87z"
                    ></path>
                    <path
                        d="M332.34 279.64c5.25 6.13 13.57 7.61 18.59 3.32s4.82-12.76-.43-18.88-13.58-7.61-18.6-3.31-4.82 12.75.44 18.87z"
                        opacity="0.2"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M332.13 280.29c5.12 6 13.21 7.45 18.07 3.29s4.64-12.39-.49-18.37-13.21-7.45-18.07-3.29-4.64 12.39.49 18.37z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M332.13 280.29c5.12 6 13.21 7.45 18.07 3.29s4.64-12.39-.49-18.37-13.21-7.45-18.07-3.29-4.64 12.39.49 18.37z"
                        opacity="0.2"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M339.19 277a3.7 3.7 0 005.14.68l1.36 1.6.93-.8.12-.75-.45.36-1-1.19a5.75 5.75 0 001.76-3.55l-1.75-1a5.1 5.1 0 01-1.33 3l-2.44-2.85c.93-1.46 1.83-3.13.14-5.1a3.68 3.68 0 00-5.12-.7l-1.37-1.61-.54.46-.34-.4v.73l1.35 1.58a4.83 4.83 0 00-1.14 2l-.29.06.06.88.28.17 1.24.73.11.07a4.93 4.93 0 01.18-.94 5.12 5.12 0 01.87-1.44l2.46 2.87c-.98 1.46-1.92 3.14-.23 5.14zm-1.33-8.73a1.66 1.66 0 012.26.31c.58.68.36 1.41-.09 2.22zm-1 .75zm3.85 6.87c-.6-.7-.38-1.45.09-2.28l2.2 2.49a1.64 1.64 0 01-2.25-.24z"
                    ></path>
                    <path
                        d="M339.19 277a3.7 3.7 0 005.14.68l1.36 1.6.93-.8.12-.75-.45.36-1-1.19a5.75 5.75 0 001.76-3.55l-1.75-1a5.1 5.1 0 01-1.33 3l-2.44-2.85c.93-1.46 1.83-3.13.14-5.1a3.68 3.68 0 00-5.12-.7l-1.37-1.61-.54.46-.34-.4v.73l1.35 1.58a4.83 4.83 0 00-1.14 2l-.29.06.06.88.28.17 1.24.73.11.07a4.93 4.93 0 01.18-.94 5.12 5.12 0 01.87-1.44l2.46 2.87c-.98 1.46-1.92 3.14-.23 5.14zm-1.33-8.73a1.66 1.66 0 012.26.31c.58.68.36 1.41-.09 2.22zm-1 .75zm3.85 6.87c-.6-.7-.38-1.45.09-2.28l2.2 2.49a1.64 1.64 0 01-2.25-.24z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M339.24 276.25c-1.7-2-.75-3.65.17-5.11l-2.41-2.87a5.39 5.39 0 00-1.18 2.34l-1.69-1a5.41 5.41 0 011.55-2.84l-1.35-1.58.92-.79 1.38 1.6a3.68 3.68 0 015.12.71c1.69 2 .78 3.64-.14 5.1l2.44 2.85a5.1 5.1 0 001.33-3l1.74 1a5.73 5.73 0 01-1.75 3.55l1.35 1.58-.93.8-1.36-1.6a3.7 3.7 0 01-5.19-.74zm3.81-.87l-2.16-2.52c-.47.83-.69 1.58-.09 2.28a1.64 1.64 0 002.25.24zm-2.89-7.55a1.66 1.66 0 00-2.25-.32l2.17 2.54c.45-.81.67-1.54.08-2.22z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M330.21 271.76s.07.42.21 1.18a14.23 14.23 0 001 3.07 15.52 15.52 0 002.64 4 16.08 16.08 0 002.1 1.89 14.89 14.89 0 002.6 1.55 16.15 16.15 0 005.64 1.51 9.28 9.28 0 004.65-.82 9.78 9.78 0 002.63-1.85c.27-.28.46-.5.6-.65s.22-.22.23-.21a2.71 2.71 0 01-.19.24c-.13.16-.31.4-.57.68a9 9 0 01-7.36 2.85 16.3 16.3 0 01-5.73-1.52 15.73 15.73 0 01-2.65-1.58 15.27 15.27 0 01-2.12-1.94 15 15 0 01-2.63-4.06 12.6 12.6 0 01-.92-3.14 6.33 6.33 0 01-.1-.88c-.03-.21-.03-.32-.03-.32zM335.43 285.39c0-.05.94.63 2.6 1.37l.66.28.73.26c.5.19 1.05.32 1.63.48a20.64 20.64 0 003.86.49 19.74 19.74 0 003.88-.34 14.71 14.71 0 001.65-.42l.74-.22.66-.26c1.69-.67 2.63-1.31 2.66-1.26a5.81 5.81 0 01-.66.44 7.78 7.78 0 01-.81.46 11.94 11.94 0 01-1.12.53l-.67.27c-.23.09-.48.16-.74.24a16.77 16.77 0 01-1.67.45A17.64 17.64 0 01341 288a13.37 13.37 0 01-1.65-.51c-.25-.09-.5-.17-.73-.27l-.66-.3a9.31 9.31 0 01-1.1-.57 7 7 0 01-.79-.49 4.4 4.4 0 01-.64-.47z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M354.64 263.82s-.51-.64-1.36-1.61a22.9 22.9 0 00-3.75-3.4 23.57 23.57 0 00-4.4-2.49c-1.2-.51-2-.74-2-.78a2.47 2.47 0 01.56.12c.35.1.85.26 1.46.5a20.08 20.08 0 014.49 2.45 20.43 20.43 0 013.73 3.48c.42.51.73.94.93 1.24a3.79 3.79 0 01.34.49zM355.61 267.17a4.42 4.42 0 01-.93-1.13 4.35 4.35 0 01-.73-1.28 4.19 4.19 0 01.93 1.13 4.35 4.35 0 01.73 1.28zM330.11 264.66s.08-.16.26-.43a4.31 4.31 0 01.35-.47 6 6 0 01.51-.58 10.15 10.15 0 011.54-1.3 10.81 10.81 0 014.58-1.79 10.34 10.34 0 012-.09c.29 0 .54.06.77.08a5 5 0 01.58.11 1.41 1.41 0 01.47.14c0 .05-.7-.12-1.83-.15a11 11 0 00-2 .14 11.18 11.18 0 00-4.49 1.75 10.62 10.62 0 00-1.54 1.24c-.77.79-1.16 1.38-1.2 1.35zM341 267.12c-.07 0-.28-.55-.9-.9a3.76 3.76 0 00-1.23-.35s.14-.1.4-.12a1.8 1.8 0 011.64 1c.09.25.09.36.09.37zM347.15 272.93c-.06 0-.09-.14-.18-.24s-.19-.14-.21-.21 0-.07.12-.08a.33.33 0 01.28.13.35.35 0 01.09.29c-.02.08-.07.12-.1.11z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M411.42 312.62c-6.93-1.57-17.58-.05-28.78 4.2s-20.16 10.18-24.31 15.95l-4.79 1.82 2.73 7.19c3 7.8 19.39 8.79 36.69 2.22s28.94-18.21 26-26l-2.73-7.2z"
                    ></path>
                    <path
                        d="M411.42 312.62c-6.93-1.57-17.58-.05-28.78 4.2s-20.16 10.18-24.31 15.95l-4.79 1.82 2.73 7.19c3 7.8 19.39 8.79 36.69 2.22s28.94-18.21 26-26l-2.73-7.2z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M390.42 337.32c-17.3 6.56-33.72 5.58-36.68-2.21s8.68-19.43 26-26 33.73-5.59 36.69 2.2-8.7 19.44-26.01 26.01z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M368.68 325.17a73.4 73.4 0 0010.38-.19c12.18-1.51 23.5-8.76 29.23-19.45-7.05-1.7-17.63-.57-28.57 3.59-10.47 4-18.86 9.8-23.2 15.51 4.05.3 8.1.46 12.16.54z"
                        opacity="0.1"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M388.84 331.39c-13.3 5-25 6.55-27 1.32-2.11-5.55 6.52-15.22 19.82-20.27s25.69-4.9 27.67.33c.49 1.29 1 2.68-.31 5.79-2.12 4.9-10.14 9.02-20.18 12.83z"
                    ></path>
                    <path
                        d="M388.84 331.39c-13.3 5-25 6.55-27 1.32-2.11-5.55 6.52-15.22 19.82-20.27s25.69-4.9 27.67.33c.49 1.29 1 2.68-.31 5.79-2.12 4.9-10.14 9.02-20.18 12.83z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M389.08 333.77c-13 4.92-25.11 4.68-27.1-.55s6.93-13.47 19.91-18.4 25.11-4.67 27.09.56-6.92 13.46-19.9 18.39z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M389.08 333.77c-13 4.92-25.11 4.68-27.1-.55s6.93-13.47 19.91-18.4 25.11-4.67 27.09.56-6.92 13.46-19.9 18.39z"
                        opacity="0.2"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M388.14 324.16l5.75-2.27a5.55 5.55 0 01-.07 2.17 6.4 6.4 0 01-.56 1.22h.2l2.28-.28.53-.07.83-1-.34-.21a5.2 5.2 0 00.21-3l3.16-1.25.56-.91-.79.31-.33-.82-3.22 1.27c-1.49-2.52-4.53-2.79-7.44-1.65-4 1.57-4.18 4-4.19 6.25l-5.7 2.25a5.94 5.94 0 01.8-4.31l-3.18.36a6.45 6.45 0 00-.7 5.16l-2.39.95-.29-.67-.49 1 .56 1.42 3.2-1.26c1.52 2.53 4.54 2.83 7.45 1.68 4.02-1.64 4.15-4.12 4.16-6.34zm0-1.65a2.75 2.75 0 011.79-2.73 2.58 2.58 0 013.28.72zm5.72-.63zm-14.26 5.63l5.05-2a2.81 2.81 0 01-1.83 2.8 2.52 2.52 0 01-3.18-.8z"
                    ></path>
                    <path
                        d="M388.14 324.16l5.75-2.27a5.55 5.55 0 01-.07 2.17 6.4 6.4 0 01-.56 1.22h.2l2.28-.28.53-.07.83-1-.34-.21a5.2 5.2 0 00.21-3l3.16-1.25.56-.91-.79.31-.33-.82-3.22 1.27c-1.49-2.52-4.53-2.79-7.44-1.65-4 1.57-4.18 4-4.19 6.25l-5.7 2.25a5.94 5.94 0 01.8-4.31l-3.18.36a6.45 6.45 0 00-.7 5.16l-2.39.95-.29-.67-.49 1 .56 1.42 3.2-1.26c1.52 2.53 4.54 2.83 7.45 1.68 4.02-1.64 4.15-4.12 4.16-6.34zm0-1.65a2.75 2.75 0 011.79-2.73 2.58 2.58 0 013.28.72zm5.72-.63zm-14.26 5.63l5.05-2a2.81 2.81 0 01-1.83 2.8 2.52 2.52 0 01-3.18-.8z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M376.84 327.33l-3.26 1.06-.47-1.45 3.23-1.05a6.51 6.51 0 011-5.11l3.2-.17a6 6 0 00-1.07 4.25l5.83-1.89c.15-2.21.52-4.66 4.58-6 3-1 6-.5 7.32 2.11l3.3-1.07.47 1.45-3.24 1.05a6 6 0 01-.62 4.16l-3.18.23a5.94 5.94 0 00.65-3.37l-5.89 1.91c-.15 2.22-.48 4.68-4.53 6-2.96 1-5.95.56-7.32-2.11zm6.34 0a2.8 2.8 0 002-2.68l-5.18 1.64a2.51 2.51 0 003.18 1.01zm5.67-5.47l5.19-1.69a2.57 2.57 0 00-3.23-.92 2.74 2.74 0 00-1.96 2.58z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M408 321.21a2.37 2.37 0 01-.26.31l-.81.84c-.34.37-.78.81-1.31 1.3s-1.12 1.06-1.81 1.64a45.16 45.16 0 01-12 7.49 60.36 60.36 0 01-13.62 3.81c-1.79.27-3.24.48-4.25.57l-1.16.12a1.22 1.22 0 01-.4 0 1.83 1.83 0 01.39-.09l1.23-.2c1-.14 2.45-.38 4.23-.69a64.36 64.36 0 0013.51-3.9 47 47 0 0012-7.34c.7-.56 1.3-1.11 1.84-1.59s1-.9 1.35-1.25l.85-.79a1.57 1.57 0 01.22-.23zM382.91 339.73s-.1 0-.31.11-.5.14-.9.23c-.78.18-1.92.43-3.34.68a55.39 55.39 0 01-11.23.93 26.19 26.19 0 01-6.17-.85 12.81 12.81 0 01-4.59-2.17 8.05 8.05 0 01-2.23-2.61 5.85 5.85 0 01-.38-.85 1.82 1.82 0 01-.1-.31s.19.41.61 1.09a8.43 8.43 0 002.26 2.47 13.07 13.07 0 004.52 2.06 27.7 27.7 0 006.09.8 61.8 61.8 0 0011.17-.82c1.42-.22 2.57-.42 3.36-.56a9.62 9.62 0 011.24-.2z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M362.87 319.74a23 23 0 01-1.91 2 31.2 31.2 0 00-4.22 5 14.11 14.11 0 00-2.26 6.06 17.65 17.65 0 000 2.71 2.56 2.56 0 01-.18-.72 8.56 8.56 0 01-.08-2 13.4 13.4 0 012.2-6.24 27.13 27.13 0 014.36-5c.63-.58 1.14-1 1.5-1.33a3 3 0 01.59-.48zM377.28 322.6a12 12 0 01-.57 1.79 12.76 12.76 0 01-.48 1.81 3.14 3.14 0 01.12-1.92 3.25 3.25 0 01.93-1.68zM361.57 345.06a5.41 5.41 0 01-2.88-1.44 5.34 5.34 0 01-1.83-2.65 21.31 21.31 0 002.07 2.37 19.4 19.4 0 002.64 1.72z"
                    ></path>
                </g>
                <g>
                    <path
                        fill="#e0e0e0"
                        d="M389.49 155.88h-61.8c0-31.1-25.46-57.17-56.57-57.17s-56.52 26.07-56.52 57.17h-61.85c0-65.27 53.1-119.12 118.37-119.12s118.37 53.85 118.37 119.12z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M271.15 44.46a110.94 110.94 0 00-34.93 5.62l15.18 47.18a62 62 0 0141.15.58l15.79-47a111 111 0 00-37.19-6.38zM382.57 155.88a110.84 110.84 0 00-18.73-61.77l-39.79 29.74a61.52 61.52 0 019 32zM159.73 155.88h49.57a61.45 61.45 0 019.08-32.23L179 93.36a110.78 110.78 0 00-19.27 62.52z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M179 93.36l39.41 30.29a62.14 62.14 0 0133-26.39l-15.19-47.18A111.92 111.92 0 00179 93.36zM308.34 50.85l-15.79 47a62.26 62.26 0 0131.5 26l39.79-29.74a112.06 112.06 0 00-55.5-43.26z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M159.73 155.88h49.57a61.45 61.45 0 019.08-32.23L179 93.36a110.78 110.78 0 00-19.27 62.52z"
                        opacity="0.5"
                    ></path>
                    <path
                        fill="#fff"
                        d="M179 93.36l39.41 30.29a62.14 62.14 0 0133-26.39l-15.19-47.18A111.92 111.92 0 00179 93.36z"
                        opacity="0.3"
                    ></path>
                    <path
                        d="M308.34 50.85l-15.79 47a62.26 62.26 0 0131.5 26l39.79-29.74a112.06 112.06 0 00-55.5-43.26z"
                        opacity="0.3"
                    ></path>
                    <path
                        d="M382.57 155.88a110.84 110.84 0 00-18.73-61.77l-39.79 29.74a61.52 61.52 0 019 32z"
                        opacity="0.5"
                    ></path>
                    <path
                        fill="#263238"
                        d="M363.84 94.11s.84 1.25 2.4 3.72a112.51 112.51 0 016.09 11.3c.58 1.27 1.21 2.61 1.79 4.06.3.72.61 1.46.92 2.22l.88 2.36c.63 1.6 1.14 3.33 1.74 5.1.26.9.53 1.81.8 2.75.14.46.27.94.41 1.41s.24 1 .37 1.46a106.13 106.13 0 012.46 12.75 116.2 116.2 0 011 14.64v.12H332.85v-.15a61.37 61.37 0 00-13.67-38.65A62.73 62.73 0 00302 102.45a64.36 64.36 0 00-10.76-4.91 61.81 61.81 0 00-36.55-1.08 60.86 60.86 0 00-11.93 4.73 60.11 60.11 0 00-10.86 7.22 61.51 61.51 0 00-22.37 47.47v.19h-49.99v-.19a111.22 111.22 0 0131.57-77.66 110.6 110.6 0 019.84-9 116.32 116.32 0 0110.54-7.6 111.81 111.81 0 0122.41-10.9 109 109 0 0122.78-5.47 110.82 110.82 0 0173.15 15.86 104.31 104.31 0 0111.44 8.16 111.52 111.52 0 018.73 7.9c2.48 2.52 4.59 4.85 6.34 6.93l2.38 2.9c.69.91 1.32 1.74 1.87 2.48s1 1.4 1.44 2l1 1.46.81 1.18s-.29-.39-.84-1.16l-1-1.45c-.4-.57-.87-1.24-1.44-2a112.1 112.1 0 00-10.62-12.26 113.9 113.9 0 00-8.76-7.86 107.87 107.87 0 00-11.43-8.13 110.93 110.93 0 00-73-15.73A108.25 108.25 0 00234 51a111.18 111.18 0 00-22.34 10.88 117.64 117.64 0 00-10.51 7.58 111.57 111.57 0 00-9.8 9 110.65 110.65 0 00-31.44 77.41l-.19-.18h49.58l-.19.18a61.9 61.9 0 0122.5-47.75 62.09 62.09 0 0122.93-12 62 62 0 0136.76 1.11 64.29 64.29 0 0110.82 4.94A63 63 0 01319.44 117a61.7 61.7 0 0113.71 38.86l-.14-.14h49.56l-.11.11a115.88 115.88 0 00-1-14.61 106.5 106.5 0 00-2.43-12.74c-.13-.49-.25-1-.37-1.45s-.27-1-.4-1.42l-.8-2.74c-.59-1.77-1.1-3.49-1.72-5.09l-.87-2.37c-.31-.75-.62-1.49-.91-2.21-.58-1.46-1.21-2.8-1.78-4.07a119 119 0 00-6-11.32c-.76-1.24-1.37-2.17-1.76-2.8s-.58-.9-.58-.9z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M263.82 153.36a8.88 8.88 0 1111.93-3.92 8.88 8.88 0 01-11.93 3.92z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M320.16 93.7a2.22 2.22 0 00-3.19-.08l-53.63 49.55 6.31 7.41 50.47-53.81a2.22 2.22 0 00.04-3.07z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M263.82 153.36a8.88 8.88 0 1111.93-3.92 8.88 8.88 0 01-11.93 3.92z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M320.16 93.7a2.22 2.22 0 00-3.19-.08l-53.63 49.55 6.31 7.41 50.47-53.81a2.22 2.22 0 00.04-3.07z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M343.47 137.45h1.77v3.41a5.81 5.81 0 01-3.39 1.07 4.35 4.35 0 110-8.69 4.51 4.51 0 013.52 1.45l-1.25 1.16a2.83 2.83 0 00-2.17-.95 2.69 2.69 0 100 5.37 3 3 0 001.51-.36zM346.52 137.58a4.6 4.6 0 114.59 4.35 4.34 4.34 0 01-4.59-4.35zm7.22 0a2.63 2.63 0 10-2.63 2.69 2.56 2.56 0 002.63-2.69zM356.65 137.58a4.6 4.6 0 114.59 4.35 4.34 4.34 0 01-4.59-4.35zm7.22 0a2.63 2.63 0 10-2.63 2.69 2.56 2.56 0 002.63-2.69zM367.3 133.39h3.82c2.74 0 4.63 1.65 4.63 4.19s-1.89 4.2-4.63 4.2h-3.82zm3.72 6.8a2.61 2.61 0 100-5.21h-1.77v5.21zM177.14 131c0 1.87-1.41 3-3.65 3h-1.69v2.31h-1.94V128h3.63c2.24 0 3.65 1.15 3.65 3zm-2 0c0-.92-.6-1.46-1.79-1.46h-1.55v2.91h1.58c1.19.03 1.79-.51 1.79-1.45zM178.11 132.18a4.6 4.6 0 114.6 4.35 4.35 4.35 0 01-4.6-4.35zm7.22 0a2.63 2.63 0 10-2.62 2.69 2.55 2.55 0 002.62-2.69zM188.24 132.18a4.6 4.6 0 114.6 4.35 4.35 4.35 0 01-4.6-4.35zm7.22 0a2.63 2.63 0 10-2.62 2.69 2.55 2.55 0 002.62-2.69zM204.25 136.38l-1.62-2.38h-1.79v2.34h-1.94V128h3.63c2.24 0 3.65 1.16 3.65 3a2.73 2.73 0 01-1.73 2.65l1.88 2.7zm-1.83-6.81h-1.58v2.93h1.58c1.19 0 1.79-.56 1.79-1.47s-.6-1.46-1.79-1.46z"
                    ></path>
                </g>
                <g>
                    <path
                        fill="#263238"
                        d="M144.33 435.13l-1.2 21.87s21.82 8.56 22 12.62l-42.47-.8.47-34.14z"
                    ></path>
                    <path
                        fill="#e0e0e0"
                        d="M130 454.9a1.73 1.73 0 00-1.23 2 1.67 1.67 0 001.93 1.24A1.84 1.84 0 00132 456a1.73 1.73 0 00-2.15-1.07"
                    ></path>
                    <path
                        fill="#455a64"
                        d="M122.65 468.81l.18-3.44 40.66 2.05s1.87.86 1.63 2.19z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M143.82 456.78c0 .21-1 .29-2.08.93s-1.59 1.51-1.78 1.43.14-1.28 1.41-2 2.5-.55 2.45-.36zM148.24 458.71c0 .2-.86.54-1.58 1.4s-.94 1.79-1.15 1.78-.31-1.19.61-2.25 2.12-1.13 2.12-.93zM150.67 464.09c-.19 0-.45-1 .1-2.13s1.53-1.56 1.62-1.38-.53.77-1 1.7-.51 1.79-.72 1.81zM143.55 451.77c-.1.19-1-.12-2.22-.06s-2.09.41-2.19.23.78-.9 2.17-.95 2.34.62 2.24.78z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M92.91 433l-2.3 26.39 4.2 21.4a2.71 2.71 0 01-2.32 3.21 2.7 2.7 0 01-2.34-.85c-3.78-4.13-17.63-19.38-17.36-21.3.32-2.23.58-28 .58-28z"
                    ></path>
                    <path
                        fill="#455a64"
                        d="M92.49 484l-19.58-24-.07 1.12a4 4 0 00.79 2.71c1.55 2.08 6 7.73 16.44 19.26a2.71 2.71 0 002.42.85z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M78.88 451.85a1.65 1.65 0 01.31 2.17 1.57 1.57 0 01-2.15.33 1.73 1.73 0 01-.31-2.3A1.64 1.64 0 0179 452"
                        opacity="0.6"
                    ></path>
                    <path
                        fill="#fff"
                        d="M87.19 472a8.31 8.31 0 012.45-1.9 8.09 8.09 0 013.08-.39c0-.06-.3-.29-.91-.42a4.22 4.22 0 00-2.4.27 4.3 4.3 0 00-1.88 1.51c-.34.55-.41.93-.34.93zM88.84 475.69a23.61 23.61 0 012.42-1 20.78 20.78 0 012.6-.17 3 3 0 00-2.73-.4c-1.59.38-2.42 1.49-2.29 1.57zM84 467.27c.12.14 1.5-1.11 3.63-1.77a25.41 25.41 0 014-.66 5.94 5.94 0 00-4.15.1 6.05 6.05 0 00-3.48 2.33zM84 459.19c.06.17 1.56-.35 3.47-.3s3.38.61 3.44.45-1.38-1-3.43-1a5.28 5.28 0 00-3.48.85zM84.48 451.43a13.35 13.35 0 013.6-.41 13.4 13.4 0 013.45 1.11c0-.06-.22-.41-.81-.8a5.89 5.89 0 00-5.29-.52c-.65.27-.99.56-.95.62zM88.13 449.54a2.51 2.51 0 00.1-1 7.15 7.15 0 00-.63-2.67 3 3 0 00-1.23-1.55 1.09 1.09 0 00-1.24.16 1.76 1.76 0 00-.49 1.17 3.84 3.84 0 003.12 3.76 4.44 4.44 0 004.51-2.05 1.51 1.51 0 00.16-1.27 1.06 1.06 0 00-1.08-.64 3.89 3.89 0 00-1.8.79 8 8 0 00-1.94 1.94 2.35 2.35 0 00-.46.89 12.19 12.19 0 012.63-2.49 3.55 3.55 0 011.58-.65.57.57 0 01.59.34 1.07 1.07 0 01-.15.84 4 4 0 01-3.93 1.72 3.32 3.32 0 01-2.7-3.15 1.37 1.37 0 01.31-.83.61.61 0 01.69-.1 2.78 2.78 0 011.06 1.3 10.34 10.34 0 01.9 3.49z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M165.37 251.52a94 94 0 012 27.66c-.45 5.88-20.29 168.51-20.29 168.51h-26.73l7.53-152.85-2.19.48s-14.63 65.18-15.82 75.74c-1.07 9.49-13.4 78.41-13.4 78.41l-24.47.28 7.82-91.16 9.1-87.48 3.43-25.46z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M123.66 279.25a5.24 5.24 0 001.11.63 13.22 13.22 0 003.32 1.16 7.27 7.27 0 002.47 0 5.08 5.08 0 002.58-1.24 6.13 6.13 0 001.56-2.74 32.81 32.81 0 00.72-3.22c.85-4.41 1.43-8.44 1.77-11.36.17-1.47.28-2.65.34-3.47a6.54 6.54 0 000-1.28 5.31 5.31 0 00-.26 1.25c-.14.93-.31 2.08-.52 3.44-.44 2.91-1.07 6.91-1.93 11.31-.43 2.18-.72 4.39-2.08 5.69a5.59 5.59 0 01-4.63 1.2 14.84 14.84 0 01-3.29-1 5.66 5.66 0 00-1.16-.37zM131.72 258.52s-.18-.14-.55-.17a2.43 2.43 0 00-1.5.49 2.64 2.64 0 00-1.13 2.06 2.66 2.66 0 004.52 1.7 2.65 2.65 0 00.51-2.3 2.4 2.4 0 00-.81-1.35c-.3-.23-.51-.26-.52-.24a3.13 3.13 0 01.94 1.66 2.32 2.32 0 01-.51 1.88 2.17 2.17 0 01-3.61-1.35 2.35 2.35 0 01.86-1.75 3 3 0 011.8-.63zM89.27 269.54a2.87 2.87 0 00.92.14 16.42 16.42 0 002.51 0 24.27 24.27 0 0015-6.33 17.55 17.55 0 001.73-1.82c.37-.46.57-.72.54-.75s-.94.92-2.54 2.27a27 27 0 01-14.8 6.23c-2.04.23-3.35.16-3.36.26zM154.38 259a12.57 12.57 0 001.24 2.73 30.1 30.1 0 004.14 5.76A28.18 28.18 0 00165 272a10 10 0 002.47 1.31 26.35 26.35 0 00-2.28-1.66 35.72 35.72 0 01-5.08-4.54 38.42 38.42 0 01-4.19-5.58c-.92-1.53-1.47-2.53-1.54-2.53z"
                    ></path>
                    <path
                        fill="#ffbf9d"
                        d="M237.55 191.6c2.84-1.07 6-2.15 6-2.15l2.79-1.11a2.42 2.42 0 010 .6 2.39 2.39 0 000 1.13 1.81 1.81 0 001.86.84 4.4 4.4 0 003.59-2.45 4.34 4.34 0 00-.31-4.16 3.28 3.28 0 000-1.24c-.64-2.75-4.48-4.21-4.48-4.21l.92-.52s3.27-.39 6.24 4.22a3.38 3.38 0 001.94 1.45 1.69 1.69 0 001.45-.15c1.1-.75.85-1.72-1-4.62s-4.52-5.11-6.82-5.3a21.88 21.88 0 00-3.2-.11s8.07-5.22 8.57-5.58c1.44-1 2.19-2.5 1.6-3.52-.38-.66-1.34-1.2-3.05-.07-1.49 1-17.95 9.08-17.95 9.08l-7.07 4-5.3 4.25 6.93 9.42.81.28a9.38 9.38 0 006.48-.08z"
                    ></path>
                    <path
                        fill="#ffbf9d"
                        d="M230.1 178.67a2.51 2.51 0 002.56 1.4c1.05 0 2.05-.43 3.09-.54 3.12-.34 6.15 1.8 9.24 1.31.43-.07.94-.29 1-.72s-.4-.67-.75-.86l-9.24-4.75"
                    ></path>
                    <path
                        fill="#ff9a6c"
                        d="M236 174.51a3.91 3.91 0 011 .41c.66.3 1.6.75 2.76 1.32l4.06 2 1.17.59c.36.22.92.34 1.18 1a.86.86 0 01-.31.92 1.82 1.82 0 01-.78.33 7.14 7.14 0 01-1.53.08 41.73 41.73 0 01-5.45-1.24 7.93 7.93 0 00-2.34-.18c-.72.07-1.4.26-2.05.37a3.65 3.65 0 01-3.05-.5c-.61-.5-.63-1-.6-1a2.77 2.77 0 00.73.79 3.59 3.59 0 002.84.27 20.22 20.22 0 012.08-.44 7.82 7.82 0 012.49.13c1.72.3 3.48 1.05 5.38 1.18a6.3 6.3 0 001.38-.06c.43-.08.75-.31.65-.53s-.48-.46-.88-.66l-1.16-.61-4-2.13c-1.14-.62-2.05-1.12-2.68-1.49a4.07 4.07 0 01-.89-.55zM246.91 178.77a4.48 4.48 0 011.9-.49 3.25 3.25 0 011.94.72 8.78 8.78 0 011.76 1.94 12.63 12.63 0 001.7 2.12 4.27 4.27 0 002 1.12 1.69 1.69 0 001.48-.33c.28-.24.35-.43.34-.44s-.15.13-.43.32a1.59 1.59 0 01-1.33.19 4.2 4.2 0 01-1.78-1.11 14.64 14.64 0 01-1.64-2.08 8 8 0 00-1.86-2 3.51 3.51 0 00-2.15-.71 3.11 3.11 0 00-1.47.41 1.27 1.27 0 00-.46.34zM255.69 167.83a10.93 10.93 0 00-1.61 1l-3.82 2.57-3.85 2.6a9.55 9.55 0 00-1.57 1.08 8.82 8.82 0 001.71-.86c1-.58 2.42-1.43 3.93-2.42s2.82-1.95 3.76-2.67a8.66 8.66 0 001.45-1.3zM246.31 181.76s.17.14.49.35a1.73 1.73 0 01.88 1.18.94.94 0 01-.39.85 4.65 4.65 0 01-1.09.61 2.17 2.17 0 00-1.13 1 1.82 1.82 0 00.18 1.63 2.1 2.1 0 001.38.89 2.65 2.65 0 001.51-.15 8.62 8.62 0 002-1.38 4.88 4.88 0 001-1.28 1.14 1.14 0 00.17-.58s-.1.2-.3.51a6.19 6.19 0 01-1.1 1.15 9.09 9.09 0 01-2 1.26 2.06 2.06 0 01-2.43-.62 1.43 1.43 0 01-.16-1.29 1.83 1.83 0 01.94-.85 4.65 4.65 0 001.14-.68 1.22 1.22 0 00.45-1.12 1.83 1.83 0 00-1.07-1.28 1.52 1.52 0 00-.47-.2z"
                    ></path>
                    <path
                        fill="#ff9a6c"
                        d="M246.79 188s-.21.05-.43.34a1.66 1.66 0 00-.2 1.47 2.22 2.22 0 001.71 1.38 3.66 3.66 0 002.64-.61 4.32 4.32 0 001.68-2.09 4.12 4.12 0 00.2-2.18 3.69 3.69 0 00-.52-1.37c-.18-.3-.32-.43-.34-.42a6.45 6.45 0 01.59 1.83 4 4 0 01-.26 2 4.13 4.13 0 01-1.56 1.91 3.43 3.43 0 01-2.36.58 2 2 0 01-1.53-1.14 1.57 1.57 0 01.08-1.29c.16-.28.32-.41.3-.41zM237.75 186s-.11-.34-.52-.75a3.57 3.57 0 00-2.13-1l.1.32a11.13 11.13 0 001-.89 8.08 8.08 0 002.24-3.84 20.22 20.22 0 01-2.52 3.58c-.31.32-.62.61-.91.87l-.33.28h.43a3.7 3.7 0 012 .8c.4.43.6.7.64.63zM251.51 177.2c-.09 0-.46.63-.29 1.53s.74 1.42.81 1.34-.3-.62-.44-1.4.02-1.44-.08-1.47z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M110 73.62c.47-4.06 3.12-6.43 6.75-8.31a12.77 12.77 0 0111.78.13c2.4 1.32 4.44 3.45 7.13 4 4.43.83 8.89-3.07 13.2-1.79A5.81 5.81 0 01147.78 79c2.9.58 4.34 4.37 3.11 7.06s-4.39 4.1-7.35 4a16.27 16.27 0 01-8.19-3.1c-2.47-1.62-4.75-3.54-7.34-5a12.64 12.64 0 00-8.5-1.75c-3.92.81-6.86 4.3-8.09 8.11s-1 7.92-.65 11.9.91 8 .31 12a1.46 1.46 0 01-.63 1.19c-.61.3-1.26-.3-1.66-.84a58.47 58.47 0 01-9.89-20.6c-1.11-4.31-1.62-8.47.23-12.52s7.53-7.68 10.86-5.8"
                    ></path>
                    <path
                        fill="#ffbf9d"
                        d="M105.12 93.11l4.24 40.64c.79 7.59 7.81 20.65 19.75 18.85l-.3-.65c10.25-2.34 7.66-14.5 6.86-23.51s11.25-2.26 10.89-13.59c-.17-5.47-1.44-16.72-2.7-26.89A21.12 21.12 0 00122 69.49l-1.09.05c-11.78 1.2-17.32 11.85-15.79 23.57z"
                    ></path>
                    <path
                        fill="#ff9a6c"
                        d="M135.67 128.44a27.39 27.39 0 01-14.94-3.72s4 8.63 15.2 7.11z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M139.4 99a1.61 1.61 0 01-1.49 1.69 1.54 1.54 0 01-1.73-1.38 1.62 1.62 0 011.48-1.7A1.55 1.55 0 01139.4 99zM141.67 93.63c-.2.22-1.46-.64-3.2-.56s-3 1-3.18.81.09-.48.63-.91a4.48 4.48 0 012.53-.91 4.24 4.24 0 012.56.71c.55.38.75.76.66.86zM124 99.65a1.63 1.63 0 01-1.49 1.7 1.54 1.54 0 01-1.73-1.39 1.62 1.62 0 011.48-1.7 1.57 1.57 0 011.74 1.39zM124.57 94.7c-.19.22-1.45-.64-3.19-.56s-3 1-3.18.8.09-.47.63-.9a4.48 4.48 0 012.53-.91 4.26 4.26 0 012.56.71c.55.38.75.76.65.86zM131.45 107.33a11.23 11.23 0 012.81-.64c.44-.06.85-.17.92-.47a2.28 2.28 0 00-.36-1.31c-.46-1.06-.95-2.16-1.46-3.32-2-4.74-3.53-8.65-3.34-8.73s2 3.7 4 8.44c.5 1.17 1 2.28 1.41 3.35a2.54 2.54 0 01.31 1.73 1.12 1.12 0 01-.7.68 2.87 2.87 0 01-.75.14 11.44 11.44 0 01-2.84.13zM124.73 87.86c-.16.48-1.9.33-3.93.66s-3.66 1-4 .56c-.13-.2.14-.66.78-1.15a6.73 6.73 0 012.9-1.15 6.56 6.56 0 013.1.22c.82.27 1.21.63 1.15.86zM140.81 88.62c-.29.41-1.51 0-2.95.09s-2.65.38-3 0c-.13-.19 0-.6.56-1a4.09 4.09 0 012.37-.76 4 4 0 012.38.7c.58.35.76.77.64.97z"
                    ></path>
                    <path
                        fill="#ffbf9d"
                        d="M106.41 101.45c-.2-.07-7.89-2-7.3 5.77s8.22 5.55 8.22 5.33-.92-11.1-.92-11.1z"
                    ></path>
                    <path
                        fill="#ff9a6c"
                        d="M104.75 109.8s-.13.1-.35.22a1.33 1.33 0 01-1 .1c-.84-.24-1.63-1.48-1.77-2.87a4.33 4.33 0 01.23-2 1.56 1.56 0 01.93-1.11.69.69 0 01.83.31c.12.21.08.37.12.38s.16-.15.07-.46a.84.84 0 00-.34-.46 1 1 0 00-.76-.14 1.89 1.89 0 00-1.32 1.3 4.62 4.62 0 00-.3 2.19c.17 1.56 1.07 3 2.22 3.19a1.41 1.41 0 001.21-.3c.23-.15.26-.34.23-.35z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M143.53 77.41a14.81 14.81 0 00-5-5.27 25.24 25.24 0 00-14.86-4.59 20.5 20.5 0 00-14.17 6.07 21.65 21.65 0 00-5.5 11.2 32 32 0 00.47 12.56 9.64 9.64 0 002.32 5c.84.8 2.23 1.29 3.12.55a3.12 3.12 0 00.82-1.83c1.23-6 2.42-12.52 0-18.18a17.79 17.79 0 015.12-5.14 9.22 9.22 0 017-1.27c3.5.92 5.77 4.18 8.63 6.4a16.4 16.4 0 0014.67 2.64z"
                    ></path>
                    <path
                        fill="#ff9a6c"
                        d="M132.71 112.09a7.51 7.51 0 01-.7 2.08 2.61 2.61 0 01-1.66 1.34 2.36 2.36 0 01-2.11-.77 3.72 3.72 0 01-.88-2.14c-.07-.7.12-1.58.8-1.77a1.76 1.76 0 01.88.06l3.87.93"
                    ></path>
                    <path
                        fill="#263238"
                        d="M131.7 115.31a5.19 5.19 0 01-2 .06 2.83 2.83 0 01-1.67-1.12 3 3 0 01-.41-2.38 2.36 2.36 0 01.24-.59c.12-.18.09-.17.25-.17s.41.1.62.14l.64.16c.83.2 1.58.37 2.21.5a6.53 6.53 0 002.08.3 6.67 6.67 0 00-2-.75c-.62-.19-1.36-.41-2.18-.64l-.63-.18c-.23 0-.4-.12-.71-.17a.85.85 0 00-.54.1.89.89 0 00-.35.38 3.29 3.29 0 00-.31.75 3.45 3.45 0 00.55 2.9 3.17 3.17 0 002.08 1.22 3 3 0 001.59-.17c.41-.15.56-.3.54-.34z"
                    ></path>
                    <path
                        fill="#455a64"
                        d="M151.34 69.32a2.18 2.18 0 01.12.51 5.33 5.33 0 010 1.52 7 7 0 01-3 4.55 10.48 10.48 0 01-8 1.18c-3.09-.6-6.12-2.27-9.3-3.8s-6.47-2.62-9.5-2.24a12.13 12.13 0 00-7.06 3.53 15.89 15.89 0 00-3.31 4.43c-.56 1.17-.77 1.86-.82 1.84a1.68 1.68 0 01.12-.51 13.79 13.79 0 01.52-1.42 15.63 15.63 0 013.11-4.58 12.37 12.37 0 017.26-3.73c3.16-.42 6.57.71 9.76 2.25s6.19 3.19 9.19 3.8a10.23 10.23 0 007.74-1 7 7 0 003.06-4.3 7.89 7.89 0 00.11-2.03zM99.62 89.83a5.7 5.7 0 01.42-2.1 13.27 13.27 0 012.59-4.49 13.51 13.51 0 012.12-1.94 4.86 4.86 0 012.16-1 2.53 2.53 0 011.6.28c.33.19.45.38.43.4a3 3 0 00-2-.33c-1.22.25-2.65 1.47-4 2.91a15.33 15.33 0 00-2.63 4.28c-.43 1.21-.6 2.01-.69 1.99zM101.28 96.59a5.88 5.88 0 01.56-2.09 14.24 14.24 0 012.81-4.39 14.43 14.43 0 014.21-3.1 6 6 0 012-.69 21.15 21.15 0 01-1.89 1 16.42 16.42 0 00-6.86 7.32 19.08 19.08 0 01-.83 1.95z"
                    ></path>
                    <path
                        fill="#f5f5f5"
                        d="M108.48 130l13.57 10.63 8.59 10.91 3.15-11.31 2.25-8.87 3.69 3.66 9.37 20 10.54 96.43s-18.38 5.38-27.9 6-24.69-1.95-24.69-1.95v-50.74l-5.4-50.24 6.51-19.25z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M135.9 256.81v-.27-.79c0-.73-.07-1.75-.11-3.06-.1-2.68-.22-6.51-.38-11.24-.33-9.48-.85-22.59-1.6-37s-1.59-27.56-2.24-37c-.33-4.72-.59-8.54-.78-11.22-.09-1.3-.16-2.32-.2-3.05 0-.33 0-.59-.05-.79a.92.92 0 010-.27 1.14 1.14 0 010 .27c0 .2 0 .46.08.79.07.72.16 1.74.28 3 .24 2.65.55 6.48.91 11.21.73 9.47 1.6 22.56 2.35 37s1.23 27.57 1.49 37.06c.13 4.75.21 8.59.25 11.25V256.55a1.39 1.39 0 010 .26z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M106.37 135l-3.15 4.33-25.7 13.53 14.31 43.62L95.68 221l-14.45 58.1s17.53 6 19.07 4.1c.65-.81 2.49-5 4.41-9.56a103 103 0 007.36-28.55c1.07-9.89 2.05-22.51 1.33-31.05-1.34-16-3.46-42.38-3.66-49.32s-1.42-33.12-1.42-33.12zM137.85 132.46l8.51 5 27.54 9-2.21 19.54-1.26 50.4 8.67 60.49s-9.25 3.28-12.91 1.93-8.67-9.63-8.86-14.64-7.33-53.61-7.33-53.61l-6.94-65.31z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M146.88 137.4a4.07 4.07 0 01.35.39l1 1.17 3.54 4.42.07.1-.1.07-3 2.28v-.25l3.51 1.11h.11v.11c-.06 6.27-.15 14.16-.24 22.71-.15 10.44-.29 19.88-.39 26.76-.07 3.4-.12 6.16-.16 8.1 0 .94-.05 1.67-.07 2.21v-2.21-8.11c.07-6.87.17-16.32.27-26.76.13-8.55.24-16.43.33-22.7l.11.15-3.51-1.14-.26-.09.22-.16 3-2.24v.17l-3.58-4.49-.9-1.21a4.69 4.69 0 01-.3-.39zM112.62 205.75a.92.92 0 01-.07-.18l-.18-.57-.71-2.16c-.61-1.87-1.52-4.57-2.7-7.9q-.88-2.49-1.94-5.42c-.72-1.94-1.38-4.08-2-6.34a139.8 139.8 0 01-2.76-14.92c-1-6.95-1.57-13.49-2-19.13V149l3-2.92v.24l-2.87-2.19-.12-.08.09-.12 5.18-7c.61-.8 1.08-1.44 1.43-1.9l.38-.48c.08-.11.13-.17.14-.16a1.92 1.92 0 01-.11.18l-.35.51-1.18 1.92-5.08 7.1v-.2l2.89 2.16.14.11-.13.13-3 2.94.06-.13c.4 5.62 1 12.16 2 19.09a147.17 147.17 0 002.72 14.9c.58 2.25 1.22 4.36 1.93 6.31l1.9 5.44c1.14 3.34 2 6.06 2.58 8 .29.94.5 1.68.63 2.18l.15.57c-.02.08-.01.15-.02.15z"
                    ></path>
                    <path
                        d="M90.55 241.74L105.5 247.6 106.5 244.27 111.58 248.92 112.49 240.84 107.8 232.1 91.65 237.34 90.55 241.74z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#ffbf9d"
                        d="M141.35 241.62l-6.07-3.68-5.28-4.83a13.63 13.63 0 004.14 2.26 3.43 3.43 0 003.86-1.47s-2.18-1.37-2.93-1.79-8.32-6.06-10.24-6.48-14.56-3-14.56-3l-.2.65-6.25 13.33 5.55 1.49a36.32 36.32 0 012.84 4.66c1.24 2.5 7.31 5 9.64 6.19s5-.92 5-.92c2.69.61 3.35-2.27 3.35-2.27 3.08-.41 3.34-3.77 3.34-3.77a72.84 72.84 0 007.32 2.47 1.06 1.06 0 001.28-.66 1.79 1.79 0 00-.79-2.18z"
                    ></path>
                    <path
                        fill="#ff9a6c"
                        d="M133.51 242a15.13 15.13 0 01-3.53-1.59 11.44 11.44 0 01-3.16-2.26c.07-.09 1.48.9 3.35 1.92a23.9 23.9 0 013.34 1.93zM123.68 241.51a15.59 15.59 0 013.49 2.06 33.25 33.25 0 013.28 2.37 15.34 15.34 0 01-3.51-2 31.46 31.46 0 01-3.26-2.43zM121.27 244.08c.07-.07 1.24.9 2.81 2a15.15 15.15 0 012.74 2.07 14.57 14.57 0 01-3-1.75 9 9 0 01-2.55-2.32zM122.83 227.8a3.28 3.28 0 011.35.29 10.16 10.16 0 012.91 1.63 13.18 13.18 0 012.34 2.37 3.93 3.93 0 01.74 1.16c-.11.09-1.32-1.64-3.32-3.22a18.28 18.28 0 00-4.02-2.23z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M64.29 226a5.7 5.7 0 01-2.68-4.63 52.29 52.29 0 01.46-7.89c1.07-8.9 15.63-60.56 15.63-60.56l14.65 40.26-5 17.53L113 223l-7.14 20.13S71.76 230.64 64.29 226zM173.9 146.48l23.52 44.72.63.92L223.7 179l8.45 17.09-34.52 21a8.61 8.61 0 01-11.56-2.61l-15.25-22.78-4.11-18.7z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M171 159.24a3 3 0 010 .56c0 .41 0 .94.06 1.61 0 1.45.06 3.47.11 6 .06 5 .09 12 0 19.62s-.23 14.6-.39 19.62c-.1 2.48-.17 4.5-.23 5.94 0 .67-.06 1.21-.08 1.62a2.05 2.05 0 01-.06.56 3.42 3.42 0 010-.56v-1.62c0-1.45.06-3.47.1-5.95.09-5 .2-12 .28-19.61s.09-14.6.1-19.62v-5.94-1.62a2.2 2.2 0 01.11-.61zM83.38 164.09a2.32 2.32 0 01.16.45l.39 1.32 1.38 4.85c1.14 4.1 2.71 9.77 4.31 16.08s2.85 12.05 3.65 16.24c.39 2.09.69 3.79.87 5 .09.55.15 1 .21 1.36a2.38 2.38 0 010 .47 2.45 2.45 0 01-.12-.46c-.07-.35-.17-.79-.28-1.34-.24-1.22-.58-2.9-1-4.95-.86-4.16-2.17-9.9-3.76-16.2s-3.12-12-4.19-16.1c-.52-2-1-3.68-1.25-4.88-.14-.55-.24-1-.32-1.34a2.64 2.64 0 01-.05-.5zM72.78 230.13l.16.05.48.17 1.85.69 6.89 2.64 23.72 9.25-.25.11 4.78-13.51 2.35-6.63.1.24-25.33-12.23a15 15 0 00-6-.68 14.65 14.65 0 00-4.43 1.12 19.41 19.41 0 00-2.57 1.35l-.63.42c-.15.09-.22.14-.23.13l.2-.16.61-.45a16.83 16.83 0 012.56-1.43 14.65 14.65 0 014.46-1.2 15 15 0 016.09.63L113 222.79l.16.08-.06.16-2.35 6.63c-1.67 4.73-3.29 9.29-4.79 13.51l-.07.18-.18-.07-23.67-9.37-6.85-2.75-1.82-.75-.47-.2zM198.23 191.66a6 6 0 01.06 1.76 27.85 27.85 0 01-.59 4.2c-.34 1.62-.69 3.07-.92 4.12a8.3 8.3 0 01-.43 1.7 6.87 6.87 0 01.15-1.75c.17-1.07.47-2.54.81-4.15s.58-3.08.7-4.15a10.85 10.85 0 01.22-1.73z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M198.23 192.74a19.34 19.34 0 01-1.23 4.06 31.28 31.28 0 01-1.61 3.92 18 18 0 011.24-4.06 29.59 29.59 0 011.6-3.92zM130.32 151.16a7.1 7.1 0 01-.72-.66l-1.91-1.89-6.45-6.6h.28a7.9 7.9 0 00-1.66 3.54 7.82 7.82 0 00-.16 2.08v.44l-.34-.28c-3.27-2.64-5.91-5.24-7.89-7-1-.89-1.77-1.6-2.33-2.09a8.52 8.52 0 01-.84-.77 5.66 5.66 0 01.93.66c.59.45 1.41 1.13 2.42 2 2 1.73 4.71 4.29 8 6.9l-.32.17a8 8 0 01.17-2.17 8.25 8.25 0 011.76-3.72l.14-.16.14.15 6.36 6.69 1.83 2a4.63 4.63 0 01.59.71zM139 136.74a2.47 2.47 0 010 .61c0 .39 0 1-.08 1.69a35.73 35.73 0 01-1 5.82l-.12.48-.22-.44a14.17 14.17 0 00-2.5-3.45c-.29-.29-.58-.57-.87-.82l.32-.1a54.52 54.52 0 01-2.33 7.7c-.37 1-.7 1.71-.94 2.22a4.32 4.32 0 01-.4.77 3.37 3.37 0 01.27-.83l.82-2.25a66.9 66.9 0 002.19-7.7l.08-.32.24.22a11.48 11.48 0 01.9.83 14.32 14.32 0 012.55 3.56h-.35a41.69 41.69 0 001.09-5.75c.21-1.39.26-2.24.35-2.24z"
                    ></path>
                    <path
                        fill="#0096FF"
                        d="M162.36 238.12L164.65 245.26 177.65 244.05 173.6 237.24 162.36 238.12z"
                    ></path>
                    <path
                        fill="#263238"
                        d="M162.36 238.12a4.9 4.9 0 01.77-.1l2.28-.23c2-.18 4.8-.45 8.18-.73h.11l.05.09 4.07 6.81.17.27h-.32l-.67.06-12.33 1.1h-.13v-.12c-.7-2.23-1.24-4-1.61-5.28l-.42-1.42a4 4 0 01-.12-.48s.08.19.2.52.3.85.5 1.44l1.74 5.13-.16-.11 12.33-1.15.67-.06-.16.3-4-6.83.16.08c-3.28.25-6.07.42-8.06.54l-2.33.14c-.6.03-.91.04-.92.03z"
                    ></path>
                    <path fill="#0096FF" d="M154.27 181.52H168.24V186.03H154.27z"></path>
                    <path
                        fill="#263238"
                        d="M168.24 186a1.62 1.62 0 010-.33v-.9c0-.82 0-1.92-.07-3.28l.13.13c-3.33 0-8.36.07-14 .07l.2-.2v4.49l-.19-.19c4 0 7.44 0 9.93.08l2.94.06a10.53 10.53 0 011.1.05s-.34 0-1 .05h-2.9c-2.48 0-6 .08-10.06.09h-.19V186v-4.49l.2-.2c5.61 0 10.64 0 14 .07h.13v.13c0 1.39-.06 2.51-.07 3.36s-.14 1.18-.15 1.13z"
                    ></path>
                    <path d="M152.12 146.84l2.15 3s-.6 44.84-2.88 56z" opacity="0.3"></path>
                </g>
            </svg>
        </div>
    );
}

export default Solution6PageImage;