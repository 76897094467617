import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import HomeHeader from "../components/HomeHeader";
import Solution3PageImage from "../components/assets/Solution3PageImage";
import EasySteps from "../components/EasySteps";


function SolutionPage3() {
    return (
        <div className="font-jakarta">
            <NavBar />
            <HomeHeader title="Easy Earn" description="Secure and easy 1 Lakh investment with a steady 3% gain." >
                <Solution3PageImage />
            </HomeHeader>
            <section>
            <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
            {/* <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Easy Earn</h2> */}
            <p class="text-base sm:text-lg text-primary-text">Imagine this: You have a sum of one lakh rupees that you'd like to invest, but you also need a steady income that can cover your expenses. Well, we have the perfect investment plan for you!<br /><br />
 
 Invest your one lakh rupees in our five-year investment program and you'll receive a Monthly return of 3%, which means a reliable income of Rs. 3,000 every Month for the next five years, so you can enjoy the benefits of your investment on a regular basis.<br /><br />
  
 That's a total of 60 payments over the course of five years, The invested amount can also be withdrawn after 5 years, making it a long-term investment that can help you achieve your financial goals. And the best part? You can rest assured that your investment is secure and being managed by a team of experts who are dedicated to ensuring your success.<br /><br />
  
 So why wait? Invest in our program today and start enjoying the peace of mind that comes with having a reliable source of income for the next five years.</p>

            </div>
            </section>
            <EasySteps />
            <Footer />
        </div>
    );
}
export default SolutionPage3;
